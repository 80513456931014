import { useParams, useHistory } from "react-router-dom";
import SurveyResultList from "./Result/List";
import FocusGroup from "./FocusGroup/FocusGroup";
import FocusGroupResList from "./FocusGroup/Results/List";
import FocusChatResList from "./FocusChat/Results/List";

function ResultList() {
    const { category, type } = useParams();
    const history = useHistory();

    const handleItemClick = (id) => {
        history.push(`/dashboard/feedback/${category}/${type}/${id}`);
    };
    return (
        <div>
            {category === "survey" && <SurveyResultList type={type} />}
            {category === "focus-group" && <FocusGroupResList type={type} />}
            {category === "focus-chat" && <FocusChatResList type={type} />}

        </div>
    );
}

export default ResultList;
