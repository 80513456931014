import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import LaunchModal from "../../Popups/LaunchModal";
import ArchiveModal from "../../Popups/ArchiveModal";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import ArchiveIcon from "@mui/icons-material/Archive";
import InsightsIcon from "@mui/icons-material/Insights";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import RepeatIcon from "@mui/icons-material/Repeat";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import "rc-pagination/assets/index.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSurveyModal from "../../Popups/CustomSurveyModal";
import { titleCase } from "../../../../../utilities/dashboardUtils";
import { Link } from "react-router-dom";
import CustomFocusGroupModal from "../../Popups/CustomFocusGroupModal";
import { GENEREATE_FOCUS_GROUP_REPORT } from "../../../../../redux/Dashboard/Dashboard.types";
import { demoFocusChat } from "../demoData";
const tableHead = {
  name: "Name",
  topic: "Metric",
  statement: "Key Insight",
  users: "Participants",
  conversations: "Chats",
  startTime: "Date/Time",
  endTime: "Duration",
  action: "Action",
};

const metricMapping = {
  morale: ["Morale Score", "Morale", "morale"],
  engagement: [
    "User Engagement",
    "User Engagement Score",
    "Overall Engagement Score",
    "Text Engagement",
    "Text Engagement Score",
    "Engagement",
  ],
  retention: ["Retention Risk"],
  eNPS: ["eNPS Engagement Topic Based Score", "eNPS Score", "ENPS"],
  topic: [],
  culture: [
    "Adaptability",
    "Celebrating Diversity",
    "Collaboration",
    "Community Involvement",
    "Constructive Feedback",
    "Creativity and Innovation",
    "Empathy",
    "Employee Recognition",
    "Gratitude and Appreciation",
    "Hate Speech",
    "Identity Hate",
    "Inclusive Language",
    "Insult",
    "Insults",
    "Obscene",
    "Open Communication",
    "Overall Positive/Negative Culture",
    "Personal Development",
    "Professionalism",
    "Racism",
    "Sexual Behaviour",
    "Sexual Harassment",
    "Supportive Language",
    "Team Celebrations",
    "Textual Insult",
    "Toxic",
    "Toxic Behaviour",
    "Transparency",
    "Wellness and Health",
    "Work-Life Balance",
  ],
};

function FocusChatResList({ setSeletedSurvey, setSelectedFocusGroup, type }) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [currentMetric, setCurrentMetric] = useState("morale");

  const focusChats = isDemo
    ? demoFocusChat
    : useSelector((data) => data.dashboard.focusChats);

  const [surveyWithResult, setSurveyWithResult] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState(
    cloneDeep(surveyWithResult.slice(0, countPerPage))
  );

  const [customModalOpen, setCustomModalOpen] = useState("");

  const searchData = useRef(
    throttle((val) => {
      const data = surveyWithResult;
      const query = val.toLowerCase();
      setCurrentPage(1);
      const byID = cloneDeep(
        data
          .filter((item) => item._id.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      const byName = cloneDeep(
        data
          .filter((item) => item.topic.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      //   const byStatus = cloneDeep(
      //     data
      //       .filter((item) => item.status.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );
      //   const byModel = cloneDeep(
      //     data
      //       .filter((item) => item.section.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );

      //   const byInsightText = cloneDeep(
      //     data
      //       .filter((item) => item.statement.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );
      //   const byInsightID = cloneDeep(
      //     data
      //       .filter((item) => item.insightID.toLowerCase().indexOf(query) > -1)
      //       .slice(0, countPerPage)
      //   );
      setCollection([
        ...byID,
        ...byName,
        // ...byStatus,
        // ...byModel,
        // ...byInsightText,
        // ...byInsightID,
      ]);
    }, 400)
  );

  useEffect(() => {
    setSurveyWithResult(
      focusChats.filter((item) =>
        // item.messages.length === 2 &&
        item.status === "completed" &&
        metricMapping[currentMetric].includes(item.topic)
      )
    );
  }, [focusChats, currentMetric]);

  useEffect(() => {
    setCollection(cloneDeep(surveyWithResult.slice(0, countPerPage)));
  }, [surveyWithResult]);

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(surveyWithResult.slice(from, to)));
  };
  const generateReport = (id) => {
    dispatch({
      type: GENEREATE_FOCUS_GROUP_REPORT,
      payload: { focusGroupID: id },
    });
  };
  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);

    const columnData = tableCell.map((keyD, i) => {
      return keyD === "startTime" ? (
        <td key={i}>{moment(key[keyD]).format("MMM DD yyyy HH:MM")}</td>
      ) : keyD === "endTime" ? (
        <td key={i}>
          {`${moment(key?.endTime).diff(
            moment(key?.startTime),
            "minutes"
          )} Minutes`}
        </td>
      ) : keyD === "users" ? (
        <td key={i}>{6}</td>
      ) : keyD === "conversations" ? (
        <td key={i}>{key.conversations.length}</td>
      ) : keyD === "name" ? (
        <td key={i}>{key.adminView?.name ? key.adminView?.name : key.topic}</td>
      ) : keyD === "action" ? (
        <Stack direction="row" spacing={2} sx={{ margin: "2rem 1rem" }}>
          <Link
            to={`/dashboard/feedback/focus-chat/insight/results/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              variant="outlined"
              style={{ border: "1px solid #D06283", color: "#484848" }}
            >
              Show
            </Button>
          </Link>
        </Stack>
      ) : (
        <td key={i}>{key[keyD]}</td>
      );
    });

    return <tr key={index}>{columnData}</tr>;
  };
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  return (
    <Box>
      <Box
        sx={{
          height: "50px",
          padding: "2rem 2rem 0",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold", textTransform: "capitalize" }}>
          {`${type} 1:1 Discussions Results`}
        </Typography>
        <Button
          onClick={() => {
            setCustomModalOpen(true);
          }}
          variant="contained"
          startIcon={<AddIcon />}
          style={{ background: "#D06283", textTransform: "capitalize" }}
        >
          Create Custom Focus Group
        </Button>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "start",
          margin: "2rem 1.5rem"
        }}
      >
        <Link
          to={`/dashboard/feedback/focus-chat/insight/results`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button

            variant={type === "insight" ? "contained" : "outlined"}
            startIcon={<InsightsIcon />}
            style={
              type === "insight"
                ? {
                  background: "#D06283",
                  textTransform: "capitalize",
                  margin: "0 0.5rem",
                  fontSize: "1.1rem",
                }
                : {
                  border: "1px solid #D06283",
                  color: "#484848",
                  textTransform: "capitalize",
                  fontSize: "1.1rem",
                  margin: "0 0.5rem",
                }
            }
          >
            Insight
          </Button>
        </Link>
        <Link
          to={`/dashboard/feedback/focus-chat/baseline/results`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button

            variant={type === "baseline" ? "contained" : "outlined"}
            startIcon={<MonitorHeartIcon />}
            style={
              type === "baseline"
                ? {
                  background: "#D06283",
                  textTransform: "capitalize",
                  margin: "0 0.5rem",
                  fontSize: "1.1rem",
                }
                : {
                  border: "1px solid #D06283",
                  color: "#484848",
                  textTransform: "capitalize",
                  fontSize: "1.1rem",
                  margin: "0 0.5rem",
                }
            }
          >
            Baseline
          </Button>
        </Link>
        <Link
          to={`/dashboard/feedback/focus-chat/recurring/results`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button

            variant={type === "recurring" ? "contained" : "outlined"}
            startIcon={<RepeatIcon />}
            style={
              type === "recurring"
                ? {
                  background: "#D06283",
                  textTransform: "capitalize",
                  margin: "0 0.5rem",
                  fontSize: "1.1rem",
                }
                : {
                  border: "1px solid #D06283",
                  color: "#484848",
                  textTransform: "capitalize",
                  fontSize: "1.1rem",
                  margin: "0 0.5rem",
                }
            }
          >
            Recurring
          </Button>
        </Link>
        <Link
          to={`/dashboard/feedback/focus-chat/custom/results`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          {" "}
          <Button

            variant={type === "custom" ? "contained" : "outlined"}
            startIcon={<SmartButtonIcon />}
            style={
              type === "custom"
                ? {
                  background: "#D06283",
                  textTransform: "capitalize",
                  margin: "0 0.5rem",
                  fontSize: "1.1rem",
                }
                : {
                  border: "1px solid #D06283",
                  color: "#484848",
                  textTransform: "capitalize",
                  fontSize: "1.1rem",
                  margin: "0 0.5rem",
                }
            }
          >
            Custom
          </Button>
        </Link>
        <Link
          to={`/dashboard/feedback/focus-chat/archived/results`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button

            variant={type === "archived" ? "contained" : "outlined"}
            startIcon={<ArchiveIcon />}
            style={
              type === "archived"
                ? {
                  background: "#D06283",
                  textTransform: "capitalize",
                  margin: "0 0.5rem",
                  fontSize: "1.1rem",
                }
                : {
                  border: "1px solid #D06283",
                  color: "#484848",
                  textTransform: "capitalize",
                  fontSize: "1.1rem",
                  margin: "0 0.5rem",
                }
            }
          >
            Archived
          </Button>
        </Link>
      </Box>
      <Box className="survey">
        <div class="survey-search">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Metric</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentMetric}
              label="Metric"
              onChange={(e) => {
                setCurrentMetric(e.target.value);
              }}
            >
              <MenuItem value={"morale"}>Morale</MenuItem>
              <MenuItem value={"engagement"}>Engagement</MenuItem>
              <MenuItem value={"retention"}>Retention</MenuItem>
              <MenuItem value={"eNPS"}>eNPS</MenuItem>
              <MenuItem value={"topic"}>Topics</MenuItem>
              <MenuItem value={"culture"}>Culture</MenuItem>
            </Select>
          </FormControl>
          <input
            style={{ margin: "0 1rem", minWidth: "500px" }}
            placeholder="Search Surveys"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <table>
          <thead>
            <tr>{headRow()}</tr>
          </thead>
          <tbody className="survey-trhover">{tableData()}</tbody>
        </table>
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={surveyWithResult.length}
        />
      </Box>
      <Modal
        open={customModalOpen}
        onClose={() => {
          setCustomModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomFocusGroupModal
          handleClose={() => {
            setCustomModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default FocusChatResList;
