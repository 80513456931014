import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { demoSurveys } from "../../Feedback/demoSurveys";
import { demoActionPlans } from "../../Assistant/Recommendation/demoActionPlans";
import { Link } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import PollIcon from '@mui/icons-material/Poll';
import { Box, Typography, Tooltip } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

export default function ActionMenu({ insight }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [open, setOpen] = useState(false);

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const currentSurvey = surveyData.filter(
    (survey) => survey.insightID === insight.surveyID
  )[0];

  const currentActionPlan = actionPlans.filter(
    (plan) => plan.insightID === insight.surveyID
  )[
    actionPlans.filter((plan) => plan.insightID === insight.surveyID).length - 1
  ];

  const toggleList = () => {
    setOpen(!open);
  };


  const demoTopicHints = {
    effectiveTopics: [
      'Job Satisfaction',
      'Motivation',
      'Communication',
      'Company Culture',
      'Leadership'
    ],
    analysis: {
      'Job Satisfaction': 'This topic demonstrated a strong positive influence on the overall engagement levels, indicating that employees feel more fulfilled and content in their roles, which likely contributed significantly to retaining talent and reducing turnover risk.',
      Motivation: 'An increase in motivation reflects that employees are driven to perform their best, which has a direct correlation with their performance and likelihood of staying with the company. Higher motivation can lead to increased productivity, enhancing the workplace environment.',
      Communication: 'Effective communication fosters a transparent culture where employees feel informed and valued. Improved communication is essential for addressing concerns and enhancing employee engagement, thereby reducing retention risk.',
      'Company Culture': 'A strong company culture creates a sense of belonging and alignment with organizational values. As the culture improves, employees are more likely to stay committed and engaged, thus positively impacting retention.',
      Leadership: 'Strong leadership is crucial for employee satisfaction and retention. When employees feel supported by their leaders, they are more likely to remain with the organization, contributing to a stable workforce.'
    }
  }

  const demoEventHints = {
    keyEvents: [
      "Bankruptcy Filing Warning",
      "CEO Resignation Shock",
      "Mass Layoffs Announced",
      "Merger Talks Uncertainty",
      "Data Breach Exposed",
      "Earnings Report Fallout",
      "Key Contract Loss",
      "Executive Decision Backlash",
      "Union Strike Intensifies",
      "Regulatory Probe Launched"
    ],
    analysis: {
      "Bankruptcy Filing Warning": "The possibility of bankruptcy creates uncertainty among investors and employees, leading to stock instability and increased attrition risk as key talent seeks more secure opportunities.",
      "CEO Resignation Shock": "An unexpected leadership change can shake investor confidence and disrupt internal operations, creating a period of uncertainty that affects employee morale and market perception.",
      "Mass Layoffs Announced": "Widespread job cuts contribute to declining employee trust, lower productivity, and negative media attention, potentially harming future hiring efforts and brand reputation.",
      "Merger Talks Uncertainty": "Merger speculations generate anxiety about potential job losses and structural changes, leading to decreased engagement and internal friction as employees seek clarity.",
      "Data Breach Exposed": "A major security breach damages customer trust and may result in regulatory fines, legal challenges, and reputational harm that can impact future business opportunities.",
      "Earnings Report Fallout": "A weak financial report signals instability, causing stock price declines and increased scrutiny from investors and analysts, which may impact future funding and growth plans.",
      "Key Contract Loss": "Losing a significant contract raises concerns about revenue streams and long-term stability, affecting stakeholder confidence and putting pressure on leadership to secure new opportunities.",
      "Executive Decision Backlash": "A controversial executive decision can lead to internal pushback, employee dissatisfaction, and potential reputational damage if not managed effectively.",
      "Union Strike Intensifies": "A prolonged strike disrupts operations, increases labor costs, and can lead to supply chain issues, affecting overall productivity and financial performance.",
      "Regulatory Probe Launched": "An investigation into business practices creates legal and compliance risks, which can lead to financial penalties, operational restrictions, and damage to corporate credibility."
    }
  };
  


  return (
    <span style={{ position: "relative" }}>
      <span
        style={{
          fontSize: "0.8rem",
          padding: "0.1rem 0.5rem",
          top: "1px",
        }}
        onClick={toggleList}

      >
        <span
          style={{
            // position: "relative",
            left: "-10px",
            top: "2px",
            cursor: "pointer",
          }}
        >
          {/* {currentWorkflowName} */}
          <MoreVertIcon />
        </span>
      </span>
      {open && (
        <div
          style={{
            position: "relative",
            zIndex: 700,
            right: "0",
          }}
        >
          <div
            style={{
              // width: "100px",
              // height: "100px",
              background: "#fff",
              left: "0px",
              padding: "0.5rem 0",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              position: "absolute",
              top: "0px",
              right: "0",
              width: "500px",
              justifyContent: "center",
              padding: "1rem",

            }}
          >
            <CloseIcon
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
              onClick={toggleList}
            />
            <Box style={{ display: "flex", }}>
              <div style={{ width: "60%" }}>
                <Typography style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                  Hints
                </Typography>

                {demoTopicHints.effectiveTopics?.map((item, idx) => (<Box style={{ display: "flex", justifyContent: "start", margin: "0.5rem 0" }}>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {demoTopicHints?.analysis ? demoTopicHints?.analysis[item] : ""}
                      </span>
                    }
                  >
                    <Typography style={{ fontWeight: "600", fontSize: "0.8rem" }}>{item}</Typography>
                  </Tooltip>

                  <Typography style={{ padding: "0.2rem 0.5rem", background: idx < 2 ? "#fee2e1" : idx < 3 ? "#fef3c7" : "#dbe9fe", color: idx < 2 ? "#b91c1b" : idx < 3 ? "#b4540a" : "#1c4ed8", borderRadius: "8px", margin: "0 1rem", fontSize: "0.7rem" }}>{idx < 2 ? "Strong" : idx < 3 ? "Moderate" : "Week"}</Typography>
                </Box>))}

                {demoEventHints.keyEvents?.sort(() => 0.5 - Math.random()).slice(0, 2).map((item, idx) => (<Box style={{ display: "flex", justifyContent: "start", margin: "0.5rem 0" }}>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {demoEventHints?.analysis ? demoEventHints?.analysis[item] : ""}
                      </span>
                    }
                  >
                    <Typography style={{ fontWeight: "600", fontSize: "0.8rem" }}>{item}</Typography>
                  </Tooltip>

                  <Typography style={{ padding: "0.2rem 0.5rem", background: idx < 2 ? "#fee2e1" : idx < 3 ? "#fef3c7" : "#dbe9fe", color: idx < 2 ? "#b91c1b" : idx < 3 ? "#b4540a" : "#1c4ed8", borderRadius: "8px", margin: "0 1rem", fontSize: "0.7rem" }}>{idx < 2 ? "Strong" : idx < 3 ? "Moderate" : "Week"}</Typography>
                </Box>))}


              </div>
              <div style={{ width: "40%", marginTop: "1rem" }}>
                <Box style={{ border: "1px solid #999", borderRadius: "8px", display: "flex", padding: "0.5rem", margin: "0.5rem 0" }}>
                  <Box style={{ display: "flex", fontSize: "0.9rem" }}>
                    <PollIcon style={{ margin: "0 0.5rem 0 0" }} />
                    View Survey
                  </Box>
                </Box>
                <Box style={{ border: "1px solid #999", borderRadius: "8px", display: "flex", padding: "0.5rem", margin: "0.5rem 0" }}>
                  <Box style={{ display: "flex", fontSize: "0.9rem" }}>
                    <GroupsIcon style={{ margin: "0 0.5rem 0 0" }} />
                    View Focus Group
                  </Box>
                </Box>
                <Box style={{ border: "1px solid #999", borderRadius: "8px", display: "flex", padding: "0.5rem", margin: "0.5rem 0" }}>
                  <Box style={{ display: "flex", fontSize: "0.9rem" }}>
                    <InterpreterModeIcon style={{ margin: "0 0.5rem 0 0" }} />
                    View 1:1 Discussion
                  </Box>
                </Box>
                <Box style={{ border: "1px solid #999", borderRadius: "8px", display: "flex", padding: "0.5rem", margin: "0.5rem 0" }}>
                  <Box style={{ display: "flex", fontSize: "0.9rem" }}>
                    <CallToActionIcon style={{ margin: "0 0.5rem 0 0" }} />
                    View Action Plan
                  </Box>
                </Box>
              </div>
            </Box>
            {/* <div>
              {_.isEmpty(currentSurvey) && (
                <MenuItem style={{ padding: "0 1rem" }}><NoteAddIcon
                  // onClick={() => {
                  //   setSurveyModalOpen(true);
                  //   setCurrentInsight(item);
                  // }}
                  style={{
                    color: "#727272",
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                />Create Survey</MenuItem>
              )}
              {!_.isEmpty(currentSurvey) && (
                <Link
                  to={`/dashboard/feedback/survey/${currentSurvey._id}`}
                  style={{
                    textDecoration: "none",
                    color: "#484848",
                  }}
                >
                  <MenuItem style={{ padding: "0 1rem" }}><FileOpenIcon
                    style={{
                      color: "#727272",
                      fontSize: "1.2rem",
                    }}
                  />View Survey</MenuItem>
                </Link>
              )}
              <MenuItem style={{ padding: "0 1rem" }}>
                <GroupsIcon
                  // onClick={() => {
                  //   setFocusModalOpen(true);
                  //   setCurrentInsight(item);
                  // }}
                  style={{
                    color: "#727272",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                /> Create Focus Group
              </MenuItem>
              
              {_.isEmpty(currentActionPlan) && (
                <MenuItem style={{ padding: "0 1rem" }}>
                  <CallToActionIcon
                    // onClick={() => {
                    //   setActionPlanModalOpen(true);
                    //   setCurrentInsight(item);
                    // }}
                    style={{
                      color: "#727272",
                      fontSize: "1.2rem",
                      cursor: "pointer",
                    }}
                  />Create Action Plan
                </MenuItem>
              )}
              {!_.isEmpty(currentActionPlan) && (
                <Link
                  to={`/dashboard/action-plan/${currentActionPlan._id}`}
                  style={{
                    textDecoration: "none",
                    color: "#484848",
                  }}
                >
                  <MenuItem style={{ padding: "0 1rem" }}>
                    <PendingActionsIcon
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                      }}
                    />View Action Plan
                  </MenuItem>
                </Link>
              )}
              
              <Link
                to={`/dashboard/feedback/focus-chat/674e992e5f31af87aa1991b8`}
                style={{
                  textDecoration: "none",
                  color: "#484848",
                }}
              >

                <MenuItem style={{ padding: "0 1rem" }}>
                  <InterpreterModeIcon
                    style={{
                      color: "#727272",
                      fontSize: "1.2rem",
                    }}
                  />View 1:1 Interview
                </MenuItem></Link>
            </div> */}
          </div>
        </div>
      )}
    </span>
  );
}
