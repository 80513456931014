import React, { useEffect, useState } from "react";

import { Box, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Markdown from "react-markdown";
import axios from "axios";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import "./index.css";
import Chart from "react-apexcharts";
import io from "socket.io-client";
import moment from "moment";
import Draggable from "react-draggable";

const base_url = process.env.REACT_APP_BASE_URL;
const socket = io(base_url);

function AssistantBox({ page }) {
  const messages = [
    "Searching for results...",
    "Please wait while we fetch the results...",
    "Loading your results...",
    "Hold tight! We're finding the best match for you...",
    "Just a moment, we're working on it!",
    "Fetching results tailored to your search...",
  ];

  const base_url = process.env.REACT_APP_BASE_URL;


  const [searchText, setSearchText] = useState("");
  const [resultBoxOpen, setResultBoxOpen] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const [result, setResult] = useState("");
  const [chatID, setChatID] = useState(null);
  const [userID, setUserID] = useState("");
  const [trendLine, setTrendLine] = useState([])
  const [series, setSeries] = useState([]);

  const generateChatID = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let chatID = "";
    for (let i = 0; i < 7; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      chatID += characters[randomIndex];
    }
    return chatID;
  };

  useEffect(() => {
    if (chatID == null) {
      setChatID(generateChatID());
    }
  }, [chatID]);

  useEffect(() => {
    socket.emit("join");
    socket.off("message");
    let userData = localStorage.getItem("userData");

    userData = JSON.parse(userData);
    userData && setUserID(userData["userId"]);
    !chatID && setChatID(generateChatID());
  }, []);

  useEffect(() => {
    const temp = []
    trendLine.forEach((trend) => {
      temp.push({
        x: new Date(trend.date),
        y: parseInt(trend.score)
      })
    })



    setSeries([{
      name: "Score",
      data: temp
    }])
  }, [trendLine])




  useEffect(() => {
    socket.on("receiveMessage", (message) => {
      console.log("Message received: ", JSON.parse(message).trendline);
      setResult(JSON.parse(message).message);
      JSON.parse(message).trendline ? setTrendLine(JSON.parse(message).trendline) : setTrendLine([])
    });
    return () => {
      socket.off("receiveMessage");
    };
  }, []);
  const sendMessage = () => {
    setResultBoxOpen(true);
    if (searchText.trim()) {
      socket.emit("sendMessage", {
        userID: userID,
        chatID: chatID,
        message: searchText,
      });
    }
  };

  useEffect(() => {
    if (resultBoxOpen && currentMessageIndex < messages.length) {
      const timer = setTimeout(() => {
        setCurrentMessageIndex(currentMessageIndex + 1);
      }, 3000); // Delay before showing next message

      return () => clearTimeout(timer);
    }
  }, [currentMessageIndex, resultBoxOpen]);



  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      sendMessage();
    }
  };



  var options = {

    chart: {
      type: "area",
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      zoom: {
        allowMouseWheelZoom: false,
      },
      events: {
        click: function (event, chartContext, config) {
          console.log(event, chartContext, config);
        },
      },

      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#0396FE"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    markers: {
      size: [3, 3],
      strokeWidth: 2,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: "#666",
      },
      labels: {
        style: {
          colors: ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && parseInt(value);
        },
      },
      title: {
        text: "Score",
        style: {
          color: "#666",
          fontSize: "12px",
        },
      },
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
      dashArray: [0, 5],
    },
    grid: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];


        const template = `${moment(data.x).format("MM-DD-YYYY")}: ${parseInt(data.y)}`;
        return template;
      },
    },
  };


  const [minimized, setMinimized] = useState(false);

  return (
    <div
      style={
        page === "insight"
          ? { minHeight: "100px" }
          : page === "feedback" ? {
            minHeight: "80px",
            position: "relative",
            top: "20px",
            left: "30px",
          } : {
            minHeight: "100px",
            position: "relative",
            top: "20px",
            left: "-20px",
          }
      }
    >{minimized ?
      // <Draggable>
      <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>


        <div onClick={() => setMinimized(false)}
          style={{
            width: "100px",
            height: "40px",
            backgroundColor: "#d06283",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: "25px",
            zIndex: 700,
            position: "absolute",
            fontSize: "1rem",
            color: "#fff",
            cursor: "pointer",
            right: page === "feedback"?"50px":page === "insight"?"20px":"0"

          }}
        >

<AutoFixHighIcon style={{ fontSize: "1rem",margin:"0 0.5rem " }} /> Ask
        </div>
      </div>
      // </Draggable>
      : <div>
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "fixed",
            right: page==="actionPlan"?"85px":"40px",
            top: page==="actionPlan"?"165px":"120px",
            zIndex: 700,
            fontSize: "1rem",
          }}
          onClick={() => {
            setMinimized(true);
          }}
        />
        <div
          style={{
            padding: "0.5rem",
            borderRadius: "8px",
            position: "fixed",
            zIndex: 700,
            width: "100vw",
          }}
        >
          <div class="input-box">
            <i class="uil uil-search"></i>
            <input
              type="text"
              placeholder="Ask Me Anything.."
              value={searchText}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
            {/* <button class="button" onClick={getResponse}>
            <AutoFixHighIcon style={{ fontSize: "1rem" }} /> AI Search
          </button> */}
            <button class="button" onClick={sendMessage}>
              <AutoFixHighIcon style={{ fontSize: "1rem" }} /> AI Search
            </button>
          </div>
          {resultBoxOpen && (
            <div class="ask-result-box">
              <CloseIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                }}
                onClick={() => {
                  setResultBoxOpen(false);
                }}
              />
              {result === searchText ? (
                <div>
                  <div className="placeholder">
                    {currentMessageIndex > 0 && messages[currentMessageIndex - 1]}
                  </div>
                  <div>
                    <Skeleton height={40} width={"50%"} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "450px",
                    overflowY: "scroll",
                    textAlign: "left",
                    // margin: "1rem 0",
                  }}
                >

                  <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Result</p>
                  <Markdown>{result}</Markdown>
                  {trendLine.length > 0 &&
                    <Chart
                      options={options}
                      series={series}
                      type="area"
                      width="100%"
                      height="290px"
                    />}
                </div>
              )}

            </div>
          )}
        </div>
      </div>}
    </div>
  );
}

export default AssistantBox;
