import { useParams } from "react-router-dom";
import SurveyResultsList from "./Result";
import BaselineRes from "./Result/BaselineSurvey";
import FocusGroupRes from "./FocusGroup/Results/FocusGroup";
import FocusChatRes from "./FocusChat/Results/FocusChat";

function ResultDetails() {
    const { category, type, id } = useParams();

    return (
        <div>
            {category === "survey" && type === "insight" && <SurveyResultsList surveyID={id} />}
            {category === "survey" && type === "baseline" && <BaselineRes surveyID={id} />}

            {category === "focus-group" && type === "insight" && <FocusGroupRes focusGroupID={id} />}

            {category === "focus-chat" && type === "insight" && <FocusChatRes focusChatID={id} />}
        </div>
    );
}

export default ResultDetails;
