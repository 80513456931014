import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import ArchiveIcon from "@mui/icons-material/Archive";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import { demoBaselineSurveys } from "../demoBaselineSurveys";
import Modal from "@mui/material/Modal";
import LaunchModal from "../Popups/LaunchModal";
import ArchiveModal from "../Popups/ArchiveModal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import CustomSurveyModal from "../Popups/CustomSurveyModal";
import { titleCase } from "../../../../utilities/dashboardUtils";
import StartBaselineModal from "../Popups/StartBaselineModal";
import StopBaselineModal from "../Popups/StopBaselineModal";
import { GET_ALL_BASELINE_SURVEYS } from "../../../../redux/Dashboard/Dashboard.types";

const tableHead = {
  surveyName: "Name",
  surveyType: "Type",
  status: "Status",
  action: "Action",
};
function ArchivedList({ setSeletedSurvey }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const dispatch = useDispatch();


  const baseLineSurveys = useSelector((data) => data.dashboard.baseLineSurveys);

  useEffect(() => {
    dispatch({ type: GET_ALL_BASELINE_SURVEYS })
  }, [])


  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState({});
  const [startBaselineModalOpen, setStartBaselineModalOpen] = useState(false);
  const [stopBaselineModalOpen, setStopBaselineModalOpen] = useState(false);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [collection, setCollection] = useState(
    cloneDeep(sortedData.slice(0, countPerPage))
  );

  const searchData = useRef(
    throttle((val) => {
      // const query = val.toLowerCase();
      // const filteredData = sortedData.filter(
      //   (item) =>
      //     item._id.toLowerCase().includes(query) ||
      //     item.surveyName.toLowerCase().includes(query) ||
      //     item.status.toLowerCase().includes(query) ||
      //     item.section.toLowerCase().includes(query) ||
      //     item.statement.toLowerCase().includes(query) ||
      //     item.insightID.toLowerCase().includes(query)
      // );
      // setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
      // setCurrentPage(1);
    }, 400)
  );

  const search = (query) => {
    // const filteredData = sortedData.filter(
    //   (item) =>
    //     item._id.toLowerCase().includes(query) ||
    //     item.surveyName.toLowerCase().includes(query) ||
    //     item.status.toLowerCase().includes(query) ||
    //     item.section.toLowerCase().includes(query) ||
    //     item.statement.toLowerCase().includes(query) ||
    //     item.insightID.toLowerCase().includes(query)
    // );
    // setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    // setCurrentPage(1);
  };

  useEffect(() => {
    setCollection(cloneDeep(sortedData.slice(0, countPerPage)));
  }, [sortedData]);

  useEffect(() => {
    let temp = baseLineSurveys.sort((a, b) => (a._id > b._id ? 1 : -1));
    setSortedData(temp);
  }, [baseLineSurveys]);

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      search(value);
      // searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(sortedData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return keyD === "action" ? (
        <Stack direction="row" spacing={2} sx={{ margin: "2rem 1rem" }}>
          <Link
            to={`/dashboard/feedback/baseline/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #D06283",
                color: "#484848",
                textTransform: "capitalize",
              }}
            >
              Show
            </Button>
          </Link>
          {key.status !== "running" ? (
            <Button
              onClick={() => {
                setStartBaselineModalOpen(true);
              }}
              variant="outlined"
              style={{
                border: "1px solid #D06283",
                background: "#D06283",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Start
            </Button>
          ) : (
            <Button
              onClick={() => {
                setStopBaselineModalOpen(true);
              }}
              variant="outlined"
              style={{
                border: "1px solid #D06283",
                background: "#D06283",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Stop
            </Button>
          )}
          <Modal
            open={startBaselineModalOpen}
            onClose={() => {
              setStartBaselineModalOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StartBaselineModal
              handleClose={() => {
                setStartBaselineModalOpen(false);
              }}
            />
          </Modal>
          <Modal
            open={stopBaselineModalOpen}
            onClose={() => {
              setStopBaselineModalOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StopBaselineModal
              handleClose={() => {
                setStopBaselineModalOpen(false);
              }}
            />
          </Modal>
        </Stack>
      ) : keyD === "status" ? (
        <td key={i}>{titleCase(key[keyD])}</td>
      ) : keyD === "surveyName" ? (
        <td key={i}>{key.adminView?.surveyName}</td>
      ) : keyD === "surveyType" ? (
        <td key={i}>{titleCase(key.topic)}</td>
      ) : (
        <td key={i}>{""}</td>
      );
    });

    return (
      <tr style={{ border: "1px solid #727272" }} key={index}>
        {columnData}
      </tr>
    );
  };
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };

  console.log("baseLineSurveys", baseLineSurveys, collection)

  return (
    <Box>
      {sortedData.length > 0 && (
        <Box>
          <Box
            sx={{
              height: "50px",
              padding: "2rem 1rem 0",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold", margin: "0 1rem" }}>
              {`Archived Surveys`}
            </Typography>
          </Box>

          <Box className="survey">
            <div class="survey-search">
              <input
                placeholder="Search Surveys"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            {<table>
              <thead>
                <tr>{headRow()}</tr>
              </thead>
              {/* <tbody className="survey-trhover">{tableData()}</tbody> */}
            </table>}
            <Pagination
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={sortedData.length}
            />
          </Box>
        </Box>
      )}
      <Modal
        open={launchModalOpen}
        onClose={() => {
          setLaunchModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LaunchModal
          survey={currentSurvey}
          handleClose={() => {
            setLaunchModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={customModalOpen}
        onClose={() => {
          setCustomModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomSurveyModal
          handleClose={() => {
            setCustomModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={archiveModalOpen}
        onClose={() => {
          setArchiveModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ArchiveModal
          survey={currentSurvey}
          handleClose={() => {
            setArchiveModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default ArchivedList;
