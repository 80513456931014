import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Markdown from "react-markdown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import EditQuestion from "./Question/EditModal";
import { demoBaselineSurveys } from "../demoBaselineSurveys";
import DeleteQuestion from "./Question/DeleteModal";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import "./index.css";
import { useLocation } from "react-router-dom";
import RenameModal from "./Question/RenameModal";
import ChangeTargetGroup from "./Question/ChangeTargetGroup";
import ReminderModal from "./Question/ReminderModal";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import ExpiryDateModal from "./Question/ExpiryDateModal";
import AddQuestion from "./Question/AddModal";
import SuggestQuestionModal from "../Popups/SuggestQuestionModal";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteModal from "../Popups/FavoriteModal";
import { useHistory } from "react-router-dom"; // Use useHistory for v5

function BaselineSurvey({ surveyID, setSelectedTab }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const location = useLocation();
  const history = useHistory();
  const isBaseline = window.location.href.includes("baseline");
  const surveyData = isDemo
    ? demoBaselineSurveys
    : useSelector((data) => data.dashboard.baseLineSurveys);

  let currentSurvey = surveyData.filter((item) => item._id === surveyID)[0];

  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [changeTGOpen, setChangeTGOpen] = useState(false);
  const [expiryDateOpen, setExpiryDateOpen] = useState(false);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [AddQuestionOpen, setAddQuestionOpen] = useState(false);

  const [suggestModalOpen, setSuggestModalOpen] = useState(false);
  const [favoriteModalOpen, setFavoriteModalOpen] = useState(false);

  const [currentList, setCurrentList] = useState("insight");

  useEffect(() => {
    // Update currentList based on the current URL
    const path = location.pathname.split("/").pop(); // Get the last part of the path
    switch (path) {
      case "insights-surveys":
        setCurrentList("insight-surveys");
        break;
      case "baseline-surveys":
        setCurrentList("baseline-surveys");
        break;
      case "recurring-surveys":
        setCurrentList("recurring-surveys");
        break;
      case "custom-surveys":
        setCurrentList("custom-surveys");
        break;
      case "archived-surveys":
        setCurrentList("archived-surveys");
        break;
      default:
        setCurrentList("insight-surveys");
    }
  }, [location.pathname]);

  return (
    <Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          {/* <Link
            to={`/dashboard/feedback/survey/`}
            style={{ textDecoration: "none", color: "#484848" }}
          > */}
          <Button
            onClick={() => {
              history.goBack();
            }}
            variant="outlined"
            style={{
              padding: "0.5rem 0",
              border: "2px solid #D06283",
              color: "#D06283",
              margin: "1rem",
            }}
          >
            <ArrowBackIcon />
          </Button>
          {/* </Link> */}
        </Box>
        {currentSurvey ? (
          <Box>
            <Box
              sx={{
                padding: "1rem 1rem",
                backgroundColor: "#f9f9f9",
                border: "1px solid #dddddd",
                borderRadius: "4px",
                margin: " 1rem 1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Baseline: {currentSurvey?.surveyName}
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        Rename Survey
                      </span>
                    }
                  >
                    <EditIcon
                      onClick={() => {
                        setRenameModalOpen(true);
                      }}
                      sx={{
                        position: "relative",
                        top: "3px",
                        left: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Typography>
                {!isBaseline && (
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "normal",
                      textAlign: "left",
                    }}
                  >
                    {`Metric: ${currentSurvey.section}`}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  Target: Overall
                  {/* <Tooltip
                      title={
                        <span
                          style={{
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                        >
                          Change Target Group
                        </span>
                      }
                    >
                      <EditIcon
                        onClick={() => {
                          setChangeTGOpen(true);
                        }}
                        sx={{
                          fontSize: "1.2rem",
                          position: "relative",
                          top: "3px",
                          left: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip> */}
                </Typography>

                <Stack direction="row">
                  <Link
                    to={`/dashboard/feedback/baseline-res/${currentSurvey._id}`}
                    style={{ textDecoration: "none", color: "#484848" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        textAlign: "left",
                        color: "#D06283",
                        cursor: "pointer",
                      }}
                    >
                      {`${currentSurvey.questions[0].response.length} ${
                        currentSurvey.questions[0].response.length > 1
                          ? "Responses"
                          : "Response"
                      }`}
                    </Typography>
                  </Link>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        Send Reminder
                      </span>
                    }
                  >
                    <NotificationsActiveIcon
                      onClick={() => {
                        setReminderOpen(true);
                      }}
                      sx={{
                        position: "relative",
                        left: "8px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Box>

              <Button
                onClick={() => {
                  setAddQuestionOpen(true);
                }}
                variant="contained"
                startIcon={<AddIcon />}
                style={{ background: "#D06283", height: "50px", textTransform: "capitalize",}}
              >
                Add Questions
              </Button>
            </Box>

            {currentSurvey?.description && (
              <Box
                style={{
                  background: "#f9f9f9",
                  border: "1px solid #dddddd",
                  borderRadius: "4px",
                  margin: "2rem 1rem 1rem",
                  textAlign: "left",
                  padding: " 0.5rem 1rem",
                }}
              >
                <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Summary
                </Typography>
                <Markdown>{currentSurvey?.description}</Markdown>
              </Box>
            )}
            <Box sx={{ padding: "1rem 1rem 1rem" }}>
              {currentSurvey.questions.map((item, idx) => (
                <Accordion
                  key={item.questionID}
                  sx={{
                    padding: " 1rem ",
                    margin: "1rem 0",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#f2f2f2",
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        marginRight: "2rem",
                      }}
                    >
                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Typography
                            sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
                          >
                            {`Q${idx + 1}. ${item.question}`}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack direction="row" spacing={2}>
                          <EditIcon
                            onClick={() => {
                              setEditModalOpen(true);
                              setCurrentQuestion(item);
                            }}
                          />
                          <DeleteIcon
                            onClick={() => {
                              setDeleteModalOpen(true);
                              setCurrentQuestion(item);
                            }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="row" spacing={2}>
                      {item.options &&
                        Object.keys(item.options)
                          .sort((a, b) => item.options[a] - item.options[b])
                          .map((option, idx) => (
                            <>
                              {option !== "Other" && (
                                <Typography>{`${
                                  idx + 1
                                }. ${option}`}</Typography>
                              )}
                            </>
                          ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Accordion
                sx={{
                  padding: " 1rem ",
                  margin: "1rem 0",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#f2f2f2",
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      marginRight: "2rem",
                    }}
                  >
                    <Box>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
                        >
                          Is there any other feedback you want to give on this
                          issue?
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="row" spacing={2}>
                    <Typography>Freeform Text</Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Typography
              onClick={() => {
                setSuggestModalOpen(true);
              }}
              style={{ textAlign: "left", margin: "0 2rem", cursor: "pointer" }}
            >
              Suggest More Question
            </Typography>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
      <Modal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditQuestion
          question={currentQuestion}
          surveyID={surveyID}
          handleClose={() => {
            setEditModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={favoriteModalOpen}
        onClose={() => {
          setFavoriteModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FavoriteModal
          surveyID={surveyID}
          handleClose={() => {
            setFavoriteModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteQuestion
          question={currentQuestion}
          surveyID={surveyID}
          handleClose={() => {
            setDeleteModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={renameModalOpen}
        onClose={() => {
          setRenameModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RenameModal
          survey={currentSurvey}
          handleClose={() => {
            setRenameModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={changeTGOpen}
        onClose={() => {
          setChangeTGOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ChangeTargetGroup
          survey={currentSurvey}
          handleClose={() => {
            setChangeTGOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={changeTGOpen}
        onClose={() => {
          setChangeTGOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ChangeTargetGroup
          survey={currentSurvey}
          handleClose={() => {
            setChangeTGOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={reminderOpen}
        onClose={() => {
          setReminderOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ReminderModal
          survey={currentSurvey}
          handleClose={() => {
            setReminderOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={expiryDateOpen}
        onClose={() => {
          setExpiryDateOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ExpiryDateModal
          survey={currentSurvey}
          handleClose={() => {
            setExpiryDateOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={AddQuestionOpen}
        onClose={() => {
          setAddQuestionOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddQuestion
          survey={currentSurvey}
          handleClose={() => {
            setAddQuestionOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={suggestModalOpen}
        onClose={() => {
          setSuggestModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuggestQuestionModal
          survey={currentSurvey}
          handleClose={() => {
            setSuggestModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default BaselineSurvey;