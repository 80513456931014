import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import api from "../../../../services/axiosConfig";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Tooltip, Typography } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import { Box } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import SettingsIcon from "@mui/icons-material/Settings";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewListIcon from '@mui/icons-material/ViewList';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircleIcon from '@mui/icons-material/Circle';
import "./index.css";
import {
  demoMultipleInsights,
  demoSingleInsights,
  demoNoneInsights,
  demoEventSingleInsight,
  demoEventMultipleInsight,
  demoEventNoneInsight,
  demoTopicNoneInsight,
  demoTopicMultipleInsight,
  demoTopicSingleInsight,
} from "../demoData";

import _ from "lodash";
import ConfigModal from "./ConfigModal";
import FocusGroupModal from "./FocusGroupModal";
import CreateSurveyModal from "./CreateSurveyModal";
import CreateActionPlanModal from "./CreateActionPlanModal";
import { demoSurveys } from "../../Feedback/demoSurveys";
import { demoActionPlans } from "../../Assistant/Recommendation/demoActionPlans";
import { demoFavoritesInsights } from "./demoData";
import ActionMenu from "./ActionMenu";

export default function Insights() {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const metricsToShow = useSelector(
    (data) => data.dashboard.metricsToShow
  );

  const singleInsights = isDemo
    ? demoSingleInsights
    : useSelector((data) => data.dashboard.singleInsights);
  const multipleInsights = isDemo
    ? demoMultipleInsights
    : useSelector((data) => data.dashboard.multipleInsights);
  const noneInsights = isDemo
    ? demoNoneInsights
    : useSelector((data) => data.dashboard.noneInsights);

  const topicSingleInsights = isDemo
    ? demoTopicSingleInsight
    : useSelector((data) => data.dashboard.topicSingleInsights);
  const topicMultipleInsights = isDemo
    ? demoTopicMultipleInsight
    : useSelector((data) => data.dashboard.topicMultipleInsights);
  const topicNoneInsights = isDemo
    ? demoTopicNoneInsight
    : useSelector((data) => data.dashboard.topicNoneInsights);

  const eventSingleInsights = isDemo
    ? demoEventSingleInsight
    : useSelector((data) => data.dashboard.eventSingleInsights);
  const eventMultipleInsights = isDemo
    ? demoEventMultipleInsight
    : useSelector((data) => data.dashboard.eventMultipleInsights);
  const eventNoneInsights = isDemo
    ? demoEventNoneInsight
    : useSelector((data) => data.dashboard.eventNoneInsights);

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [focusModalOpen, setFocusModalOpen] = useState(false);
  const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const [actionPlanModalOpen, setActionPlanModalOpen] = useState(false);
  const [currentInsight, setCurrentInsight] = useState({});
  const [selectedOption, setSelectedOption] = useState("Morale");
  const [selectedSwich, setSelectedSwitch] = useState("none");
  const [positiveOrNegative, setPositiveOrNegative] = useState("negative");
  const [selectedNumber, setSelectedNumber] = useState(2);
  const [eventBased, setEventBased] = useState(false);
  const [topicBased, setTopicBased] = useState(false);
  const [points, setPoints] = useState([]);
  const [favoritePoints, setFavoritePoints] = useState([]);
  const [favoriteFilter, setFavoriteFilter] = useState("top");
  const [insights, setInsights] = useState({});
  const [insightByMonth, setInsightByMonth] = useState([]);
  const [favInsightsByMonth, setFavInsightsByMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getLastMonth(insights));
  const [interval, setInterval] = useState("monthly");
  const [currentQuarter, setCurrentQuarter] = useState("q3");
  const [currentHalf, setCurrentHalf] = useState("first");
  const [currentYear, setCurrentYear] = useState("2023");
  const [insightsForList, setInsightsForList] = useState([]);

  const [view, setView] = useState("organized");

  const handleChangeView = (event, newView) => {
    setView(newView);
  };


  useEffect(() => {
    setSelectedOption(metricsToShow[0])
  }, [metricsToShow])

  useEffect(() => {
    if (eventBased) {
      selectedSwich === "single"
        ? setInsights(eventSingleInsights)
        : selectedSwich === "multiple"
          ? setInsights(eventMultipleInsights)
          : setInsights(eventNoneInsights);
    } else if (topicBased) {
      selectedSwich === "single"
        ? setInsights(topicSingleInsights)
        : selectedSwich === "multiple"
          ? setInsights(topicMultipleInsights)
          : setInsights(topicNoneInsights);
    } else {
      selectedSwich === "single"
        ? setInsights(singleInsights)
        : selectedSwich === "multiple"
          ? setInsights(multipleInsights)
          : setInsights(noneInsights);
    }
  }, [
    eventSingleInsights,
    eventMultipleInsights,
    eventNoneInsights,
    topicSingleInsights,
    topicMultipleInsights,
    topicNoneInsights,
    singleInsights,
    multipleInsights,
    noneInsights,
    selectedSwich,
    topicBased,
    eventBased,
    selectedMonth,
  ]);

  const changeMonth = (event) => {
    setSelectedMonth(parseInt(event.target.value));
    setSelectedSwitch("multiple");
  };
  useEffect(() => {
    setInsightByMonth(
      !_.isEmpty(insights) &&
      insights.filter(
        (item) => moment(item.timeProcessed).month() + 1 === selectedMonth
      )[0]
    );
  }, [insights]);

  useEffect(() => {
    setFavInsightsByMonth(
      !_.isEmpty(demoFavoritesInsights) &&
      demoFavoritesInsights.filter(
        (item) => moment(item.month).month() + 1 === selectedMonth
      )[0]
    );
  }, [demoFavoritesInsights]);

  const getPoints = (insight, option) => {
    if (!insight) return [];
    switch (option) {
      case "Morale":
        return selectedSwich === "none"
          ? insight.insight?.morale
          : positiveOrNegative === "positive"
            ? insight.positiveinsight
              ? insight.positiveinsight?.morale || []
              : insight.insight?.morale
            : insight.negativeinsight
              ? insight.negativeinsight?.morale || []
              : insight.insight?.morale;
      case "Engagement":
        return selectedSwich === "none"
          ? insight.insight?.engagement
          : positiveOrNegative === "positive"
            ? insight.positiveinsight
              ? [...(insight.positiveinsight?.engagement || [])]
              : insight.insight?.engagement
            : insight.negativeinsight
              ? [...(insight.negativeinsight?.engagement || [])]
              : [...(insight.insight?.engagement || [])];
      case "Retention Risk":
        return selectedSwich === "none"
          ? insight.insight?.retention
          : positiveOrNegative === "positive"
            ? insight.positiveinsight
              ? insight.positiveinsight?.retention || []
              : insight.insight?.retention
            : insight.negativeinsight
              ? insight.negativeinsight?.retention || []
              : insight.insight?.retention;
      case "eNPS":
        return selectedSwich === "none"
          ? insight.insight?.enps
          : positiveOrNegative === "positive"
            ? insight.positiveinsight
              ? [...(insight.positiveinsight?.enps || [])]
              : insight.insight?.enps
            : insight.negativeinsight
              ? [...(insight.negativeinsight?.enps || [])]
              : insight.insight?.enps;

      case "Topics":
        return selectedSwich === "none"
          ? insight.insight?.topic
          : positiveOrNegative === "positive"
            ? insight.positiveinsight
              ? [...(insight.positiveinsight?.topic || [])]
              : insight.insight?.topic
            : insight.negativeinsight
              ? [...(insight.negativeinsight?.topic || [])]
              : insight.insight?.topic;
      case "Culture":
        return selectedSwich === "none"
          ? insight.insight?.culture
          : positiveOrNegative === "positive"
            ? insight.positiveinsight
              ? [...(insight.positiveinsight?.culture || [])]
              : insight.insight?.culture
            : insight.negativeinsight
              ? [...(insight.negativeinsight?.culture || [])]
              : insight.insight?.culture;
      default:
        return [];
    }
  };

  const fetchStatements = (insights) => {
    let statements = [];

    insights.length > 0 && insights.forEach(insight => {
      Object.values(insight.insight).forEach(category => {
        category.forEach(item => {
          if (item.statement) {
            statements.push(item.statement);
          }
        });
      });
    });

    return statements;
  };

  useEffect(() => {
    setInsightsForList(fetchStatements(insights))
  }, [insights])

  console.log("insights", insightsForList)
  const getFavoritePoints = (insight, option) => {
    if (!insight) return [];
    switch (option) {
      case "Morale":
        return insight?.morale;
      case "Engagement":
        return insight?.engagement;
      case "Retention Risk":
        return insight?.retention;
      case "eNPS":
        return insight?.enps;
      case "Topics":
        return insight?.topic;
      case "Culture":
        return insight?.culture;
      default:
        return [];
    }
  };
  useEffect(() => {
    favoriteFilter === "favorite"
      ? setPoints(getFavoritePoints(favInsightsByMonth, selectedOption))
      : setPoints(getPoints(insightByMonth, selectedOption));
  }, [
    insightByMonth,
    selectedSwich,
    selectedOption,
    insights,
    selectedMonth,
    positiveOrNegative,
    favoriteFilter,
    demoFavoritesInsights,
  ]);

  const options = [
    "Morale",
    "Engagement",
    "Retention Risk",
    "eNPS",
    // "Supporter/Detractor",
    "Topics",
    "Culture",
  ];

  function getLastMonth(insights) {
    if (insights.length === 0) return null;

    const lastInsight = insights[insights.length - 1];
    const lastMonth = moment(lastInsight?.timeProcessed).month() + 1; // moment().month() returns 0-11, adding 1 for 1-12
    return lastMonth;
  }

  const renderinsight = (item, index) => {
    const currentSurvey = surveyData.filter(
      (survey) => survey.insightID === item.surveyID
    )[0];

    const currentActionPlan = actionPlans.filter(
      (plan) => plan.insightID === item.surveyID
    )[
      actionPlans.filter((plan) => plan.insightID === item.surveyID).length - 1
    ];

    const wordsToBold = [
      "decline",
      "increase",
      "decreased",
      "decrease",
      "increased",
      "dropped",
      "rose",
    ];

    // Combine wordsToBold and percentage regex
    const regex = new RegExp(
      `(${wordsToBold.join("|")}|\\d+(\\.\\d+)?%)`, // Matches words or valid percentages
      "gi"
    );

    // Replace matches with bold tags directly
    const renderBoldText = (inputText) =>
      inputText.replace(regex, (match) => `<strong>${match}</strong>`);

    return (
      <li
        style={{
          background: "#fff",
          borderRadius: "8px",
          color: "#484848",
          textAlign: "left",
          margin: "0.5rem 0",
          position: "relative"
          // display: "flex",
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: renderBoldText(item.statement?.trim()),
          }}
        />
        <ActionMenu insight={item} />
      </li>
    );
  };

  const getInsights = async () => {
    const insightsResponse = await api.post("getInsight/singleQuarterly", {}, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    console.log("insightsResponse", insightsResponse)
  }

  useEffect(() => {
    getInsights()
  }, [])
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "2rem 0 1.5rem",
      }}
    >
      <Box
        style={{
          background: "#fff",
          borderRadius: "12px",
          marginLeft: ".1rem",
          padding: "1rem 0 0",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          width: "100%",
          height: "375px"
        }}
      >
        <SettingsIcon
          onClick={() => {
            setConfigModalOpen(true);
          }}
          style={{
            color: "#727272",
            cursor: "pointer",
            zIndex: "400",
            position: "absolute",
            right: "65px",
            top: "-60px",
          }}
        />
        <Box style={{ position: "absolute", top: "15px", left: "15px" }}>
          <ToggleButtonGroup
            size="small"
            value={view}
            exclusive
            onChange={handleChangeView}
            aria-label="text alignment"
          >
            <ToggleButton value="list" aria-label="left aligned">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="organized" aria-label="right aligned">
              <AccountTreeIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <div class="switches-container">
          <div style={{ display: "flex" }}>
            <Box
              style={{
                filter:
                  selectedSwich === "none" || favoriteFilter === "favorite"
                    ? "opacity(0.5)"
                    : "",
                pointerEvents:
                  selectedSwich === "none" || favoriteFilter === "favorite"
                    ? "none"
                    : "",
                cursor:
                  selectedSwich === "none" || favoriteFilter === "favorite"
                    ? "not-allowed"
                    : "",
                margin: "0 0.5rem",
              }}
            >
              <FormControl fullWidth>
                <Typography style={{ fontSize: "0.8rem", textAlign: "left" }}>
                  Type
                </Typography>
                <Select
                  value={positiveOrNegative}
                  input={<OutlinedInput sx={{ padding: "0" }} />}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    setPositiveOrNegative(e.target.value);
                  }}
                  sx={{ padding: "0", fontSize: "0.8rem" }}
                >
                  <MenuItem value={"positive"}>{"Positive"}</MenuItem>
                  <MenuItem value={"negative"}>{"Negative"}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box style={{ margin: "0 0.5rem" }}>
              <FormControl fullWidth>
                <Typography style={{ fontSize: "0.8rem", textAlign: "left" }}>
                  Type
                </Typography>
                <Select
                  value={favoriteFilter}
                  input={<OutlinedInput sx={{ padding: "0" }} />}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    setFavoriteFilter(e.target.value);
                  }}
                  sx={{ padding: "0", fontSize: "0.8rem" }}
                >
                  <MenuItem value={"favorite"}>{"Favorite"}</MenuItem>
                  <MenuItem value={"top"}>{"Top"}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              style={{
                filter: favoriteFilter === "favorite" ? "opacity(0.5)" : "",
                pointerEvents: favoriteFilter === "favorite" ? "none" : "",
                cursor: favoriteFilter === "favorite" ? "not-allowed" : "",
                margin: "0 0.5rem",
              }}
            >
              <FormControl fullWidth>
                <Typography style={{ fontSize: "0.8rem", textAlign: "left" }}>
                  Filters
                </Typography>
                <Select
                  value={selectedSwich}
                  input={<OutlinedInput sx={{ padding: "0" }} />}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    setSelectedSwitch(e.target.value);
                  }}
                  sx={{ padding: "0", fontSize: "0.8rem" }}
                >
                  <MenuItem value={"none"}>{"None"}</MenuItem>
                  <MenuItem value={"single"}>{"Single"}</MenuItem>
                  <MenuItem value={"multiple"}>{"Multiple"}</MenuItem>
                </Select>
              </FormControl>
            </Box>{" "}
            <Box component="div" style={{ margin: "0 0.5rem" }}>
              <Box>
                <FormControl fullWidth>
                  <Typography style={{ fontSize: "0.8rem", textAlign: "left" }}>
                    Interval
                  </Typography>
                  <Select
                    value={interval}
                    input={<OutlinedInput sx={{ padding: "0" }} />}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => {
                      setInterval(e.target.value);
                    }}
                    sx={{ padding: "0", fontSize: "0.8rem" }}
                  >
                    <MenuItem value={"monthly"}>{"Monthly"}</MenuItem>
                    <MenuItem value={"quarterly"}>{"Quarterly"}</MenuItem>
                    <MenuItem value={"bi-annually"}>{"Bi-Annually"}</MenuItem>
                    <MenuItem value={"annually"}>{"Annually"}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {interval === "monthly" && (
              <Box component="div" style={{ margin: "0 0.5rem" }}>
                <Box>
                  <FormControl fullWidth>
                    <Typography
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      Month
                    </Typography>
                    <Select
                      value={selectedMonth}
                      input={<OutlinedInput sx={{ padding: "0" }} />}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={changeMonth}
                      sx={{ padding: "0", fontSize: "0.8rem" }}
                    >
                      {!_.isEmpty(insights) &&
                        insights
                          .sort(
                            (a, b) =>
                              new Date(b.timeProcessed) -
                              new Date(a.timeProcessed)
                          )
                          .map((item) => (
                            <MenuItem
                              value={moment(item.timeProcessed).month() + 1}
                            >
                              {moment(item.timeProcessed).format("MMMM 'YY")}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            {interval === "quarterly" && (
              <Box component="div" style={{ margin: "0 0.5rem" }}>
                <Box>
                  <FormControl fullWidth>
                    <Typography
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      Quarter
                    </Typography>
                    <Select
                      value={currentQuarter}
                      input={<OutlinedInput sx={{ padding: "0" }} />}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) => {
                        setCurrentQuarter(e.target.value);
                      }}
                      sx={{ padding: "0", fontSize: "0.8rem" }}
                    >
                      <MenuItem value={"q1"}>{"Quarter 1"}</MenuItem>
                      <MenuItem value={"q2"}>{"Quarter 2"}</MenuItem>
                      <MenuItem value={"q3"}>{"Quarter 3"}</MenuItem>
                      <MenuItem value={"q4"}>{"Quarter 4"}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            {interval === "bi-annually" && (
              <Box component="div" style={{ margin: "0 0.5rem" }}>
                <Box>
                  <FormControl fullWidth>
                    <Typography
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      Bi-Annual
                    </Typography>
                    <Select
                      value={currentHalf}
                      input={<OutlinedInput sx={{ padding: "0" }} />}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) => {
                        setCurrentHalf(e.target.value);
                      }}
                      sx={{ padding: "0", fontSize: "0.8rem" }}
                    >
                      <MenuItem value={"first"}>{"First Half"}</MenuItem>
                      <MenuItem value={"second"}>{"Second Half "}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            {interval === "annually" && (
              <Box component="div" style={{ margin: "0 0.5rem" }}>
                <Box>
                  <FormControl fullWidth>
                    <Typography
                      style={{ fontSize: "0.8rem", textAlign: "left" }}
                    >
                      Annual
                    </Typography>
                    <Select
                      value={currentYear}
                      input={<OutlinedInput sx={{ padding: "0" }} />}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) => {
                        setCurrentYear(e.target.value);
                      }}
                      sx={{ padding: "0", fontSize: "0.8rem" }}
                    >
                      <MenuItem value={"2022"}>{"2022"}</MenuItem>
                      <MenuItem value={"2023"}>{"2023"}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </div>
        </div>
        <ContentCopyOutlinedIcon
          onClick={() => {
            try {
              const textToCopy =
                view === "list"
                  ? `Insights:\n${insightsForList.map((item) => `${item}\n`).join('')}`
                  : `Insights:\n${points.map((item) => `${item.statement}\n`).join('')}`;

              navigator.clipboard.writeText(textToCopy);
            } catch (err) {
              console.error("Failed to copy text: ", err);
            }
          }}
          style={{
            fontSize: "1.2rem",
            cursor: "pointer",
            position: "absolute",
            top: "100px",
            right: "15px",
            zIndex: 700,
          }}
        />

        {(view === "list" && insightsForList.length > 0) ? <List style={{ height: "280px", overflowY: "scroll", position: "relative", top: "60px", margin: "0.5rem" }}>
          {insightsForList.map((item, idx) => (<ListItem style={{ color: "#484848", display: "flex" }} key={`insight-${idx + 1}`}><CircleIcon style={{ fontSize: "0.3rem", margin: "0 0.5rem" }} />{item}</ListItem>))}
        </List>
          : <Grid
            container
            spacing={3} style={{ position: "relative", top: "70px", margin: "0" }}>
            <Grid item xs={3.5}>
              <div
                style={{
                  width: "100%",
                }}
              >
                {metricsToShow.map((item) => (
                  <li
                    onClick={() => {
                      setSelectedOption(item);
                    }}
                    style={{
                      listStyle: "none",
                      margin: "0.5rem 1rem",
                      padding: "0.5rem",
                      width: "100%",
                      background: selectedOption === item ? "#E8E8E8" : "#fff",
                      borderRadius: "6px",
                      fontWeight: "600",
                      color: "#484848",
                      cursor: "pointer",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </div>
            </Grid>
            <Grid item xs={7.5}>
              <div
                style={{
                  // width: "95%",
                  position: "relative",
                  left: "60px",
                  top: "10px",
                  overflow: "visible",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "600",
                    color: "#484848",
                    margin: "1rem 0 1rem",
                  }}
                >
                  {selectedOption} Insights
                </p>
                <div style={{ minHeight: "330px", overflow: "visible" }}>
                  {!_.isEmpty(points) ? (
                    <div>
                      {points && points.slice(0, selectedNumber).map(renderinsight)}
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "start",
                          display: "flex",
                        }}
                      >
                        {selectedNumber === 2 && (
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                See More
                              </span>
                            }
                          >
                            <MoreHorizIcon
                              onClick={() => {
                                setSelectedNumber(points.length);
                              }}
                              style={{ fontSize: "2rem", cursor: "pointer" }}
                            />
                          </Tooltip>
                        )}
                        {selectedNumber !== 2 && (
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                See Less
                              </span>
                            }
                          >
                            <ExpandLessIcon
                              onClick={() => {
                                setSelectedNumber(2);
                              }}
                              style={{ fontSize: "2rem", cursor: "pointer" }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Stack spacing={1}>
                      <Skeleton variant="text" width={"95%"} height={"60px"} />
                      <Skeleton
                        variant="rectangular"
                        width={"95%"}
                        height={"150px"}
                      />
                    </Stack>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>}
      </Box>
      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfigModal
          eventBased={eventBased}
          topicBased={topicBased}
          setEventBased={setEventBased}
          setTopicBased={setTopicBased}
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={focusModalOpen}
        onClose={() => {
          setFocusModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FocusGroupModal
          currentInsight={currentInsight}
          metric={selectedOption}
          handleClose={() => {
            setFocusModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={surveyModalOpen}
        onClose={() => {
          setSurveyModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateSurveyModal
          currentInsight={currentInsight}
          metric={selectedOption}
          handleClose={() => {
            setSurveyModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={actionPlanModalOpen}
        onClose={() => {
          setActionPlanModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateActionPlanModal
          currentInsight={currentInsight}
          metric={selectedOption}
          handleClose={() => {
            setActionPlanModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
