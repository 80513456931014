import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  GET_CULTURE_TOPICS_TO_SHOW_SUCCESS,
  GET_POSITIVE_CULTURE_THRESHOLD,
  SAVE_POSITIVE_CULTURE_THRESHOLD,
} from "../../../../../redux/Dashboard/Dashboard.types";
import SetGoal from "../../../../common/SetGoal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const topicList = [
  "Adaptability",
  "Celebrating Diversity",
  "Collaboration",
  "Community Involvement",
  "Constructive Feedback",
  "Creativity & Innovation",
  "Empathy",
  "Employee Recognition",
  "Gratitude & Appreciation",
  "Hate Speech",
  "Identity Hate",
  "Inclusive Language",
  "Insulting Language",
  "Mentoring Mentions",
  "Obscene",
  "Open Communication",
  "Personal Development",
  "Professionalism",
  "Racism",
  "Severe Toxic",
  "Sexual Harassment",
  "Supportive Language",
  "Team Celebrations",
  "Threat",
  "Toxic",
  "Transparency",
  "Wellness & Health",
  "Work-life Balance",
];

// Helper function to generate random values that sum to 100
const generateRandomValues = (count) => {
  let randomValues = Array(count).fill(0);
  let sum = 0;

  // Generate random values and calculate the sum
  for (let i = 0; i < count; i++) {
    randomValues[i] = Math.random();
    sum += randomValues[i];
  }


  // Scale the values so that they sum to 100
  const scaleFactor = 100 / sum;
  randomValues = randomValues.map((value) => Math.round(value * scaleFactor));

  // Adjust the values to ensure the total sum is exactly 100
  let total = randomValues.reduce((acc, curr) => acc + curr, 0);
  while (total !== 100) {
    const diff = 100 - total;
    const index = Math.floor(Math.random() * count);
    randomValues[index] = randomValues[index] + diff;
    total = randomValues.reduce((acc, curr) => acc + curr, 0);
  }

  return randomValues;
};

function PositiveConfigModal({ plan, handleClose }) {
  const dispatch = useDispatch();

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    dispatch({ type: GET_POSITIVE_CULTURE_THRESHOLD });
  }, []);

  const positiveThreshold = useSelector(
    (data) => data.dashboard.positiveThreshold || {}
  );

  const cultureTopicsToShow = useSelector(
    (data) => data.dashboard.cultureTopicsToShow
  );

  const [values, setValues] = useState({});



  const handleChange = (metric) => (e) => {
    setValues({ ...values, [metric]: e.target.value });
  };

  const handleSave = () => {
    const payload = Object.keys(values).reduce((acc, key) => {
      acc[key] = parseInt(values[key]);
      return acc;
    }, {});

    dispatch({
      type: SAVE_POSITIVE_CULTURE_THRESHOLD,
      payload,
    });

    setTimeout(() => {
      dispatch({ type: GET_POSITIVE_CULTURE_THRESHOLD });
    }, 3000);

    handleClose();
  };

  const handleTopicChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index] = event.target.value;
    setTopics(newTopics);
  };

  const addTopicField = () => {
    setTopics([...topics, ""]);
  };

  const removeTopicField = (index) => {
    const newTopics = topics.filter((_, i) => i !== index);
    setTopics(newTopics);
  };


  const [checkboxTopics, setCheckboxTopics] = useState([...new Set([...cultureTopicsToShow, ...topicList])])

  const [checkedItems, setCheckedItems] = useState(
    checkboxTopics.sort().reduce((acc, topic) => {
      acc[topic] = cultureTopicsToShow.includes(topic);
      return acc;
    }, {})
  );
  const [cultureTopics, setCultureTopics] = useState([]);


  useEffect(() => {
    setCultureTopics(Object.keys(checkedItems).filter(key => checkedItems[key]))
  }, [checkedItems]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleTopicSave = () => {
    dispatch({ type: GET_CULTURE_TOPICS_TO_SHOW_SUCCESS, payload: [...cultureTopics, ...topics] })
    console.log(cultureTopics)
    handleClose();
  }


  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        <Typography
          id="modal-modal-title"
          style={{ margin: "1rem 0", fontSize: "1.1rem" }}
        >
          Set Goal
        </Typography>

        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <SetGoal metric="culture" handleClose={handleClose} />
        </Box>
        <hr />
        <Box style={{ marginBottom: "1rem" }}>
          <Typography
            id="modal-modal-title"
            style={{ margin: "1rem 0", fontSize: "1.1rem" }}
          >
            Culture Score Weighting
          </Typography>

          <Grid container spacing={2}>
            {topicList.map((metric, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <TextField
                  fullWidth
                  id={`question-text-${metric}`}
                  type="number"
                  label={metric}
                  variant="outlined"
                  value={5} // Ensure value defaults to 0 if undefined
                  onChange={handleChange(metric)}
                />
              </Grid>
            ))}
          </Grid>
          <Box style={{ margin: "1rem 0" }}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button
                onClick={handleSave}
                style={{
                  background: "#D06283",
                  color: "#fff",
                }}
                variant="contained"
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
        <hr />
        <Box style={{ margin: "1rem 0" }}>
          <Typography
            id="modal-modal-title"
            style={{ margin: "1rem 0 0", fontSize: "1.1rem" }}
          >
            Culture Topics
          </Typography>

          <Box style={{ margin: "1rem 1rem 0" }}>
            <Grid container >
              {checkboxTopics.map((topic, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={checkedItems[topic]}
                        onChange={handleCheckboxChange}
                        name={topic}
                      />
                    }
                    style={{ fontSize: "1rem" }}
                    label={topic}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Stack direction="column" spacing={2}>
            {topics.map((topic, index) => (
              <Stack
                direction="row"
                spacing={2}
                key={index}
                alignItems="center"
              >
                <TextField
                  fullWidth
                  id={`topic-text-${index}`}
                  label={`Topic ${index + 1}`}
                  variant="outlined"
                  value={topic}
                  onChange={(event) => handleTopicChange(index, event)}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => removeTopicField(index)}
                >
                  Remove
                </Button>
              </Stack>
            ))}
            <Typography variant="outlined" onClick={addTopicField} style={{ margin: "0 1rem", cursor: "pointer" }}>
              <AddIcon style={{ position: "relative", top: "5px" }} /> Add
            </Typography>
          </Stack>
          <Box style={{ margin: "1rem 0" }}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button
                onClick={handleTopicSave}
                style={{
                  background: "#D06283",
                  color: "#fff",
                }}
                variant="contained"
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PositiveConfigModal;
