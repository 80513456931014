import React, { useState } from "react";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Typography, Button, IconButton, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SET_EXTERNAL_EVENTS_MODAL } from "../../redux/Dashboard/Dashboard.types";

const sampleNotificationsData = [
  {
    id: 0,
    title: "New External Events found",
    message: "We’ve discovered some events that you may want to include in your company’s event lineup.",
    timestamp: "5 minutes ago",
    read: false,
    type: "external-events",
  },
  {
    id: 1,
    title: "New Monthly Report",
    message: "The latest monthly performance report is now available.",
    timestamp: "5 minutes ago",
    read: false,
    type: "info",
  },
  {
    id: 2,
    title: "Survey Results Available",
    message:
      "Results for the latest employee morale survey are ready for review.",
    timestamp: "Yesterday",
    read: true,
    type: "success",
  },
  {
    id: 3,
    title: "New Recommended Focus Groups",
    message: "New focus groups have been recommended based on recent insights.",
    timestamp: "2 days ago",
    read: true,
    type: "announcement",
  },
  {
    id: 4,
    title: "Upcoming Company Meeting",
    message:
      "The next company-wide meeting is scheduled for Monday at 10:00 AM.",
    timestamp: "1 hour ago",
    read: false,
    type: "event",
  },
  {
    id: 5,
    title: "New Security Policy Update",
    message:
      "A new update to the company's security policy has been published.",
    timestamp: "3 days ago",
    read: true,
    type: "info",
  },
  {
    id: 6,
    title: "Employee Recognition Event",
    message:
      "Join us in congratulating this quarter's top-performing employees.",
    timestamp: "5 days ago",
    read: true,
    type: "announcement",
  },
  {
    id: 7,
    title: "Weekly Performance Insights",
    message: "Your team's weekly performance insights are now available.",
    timestamp: "30 minutes ago",
    read: false,
    type: "success",
  },
  {
    id: 8,
    title: "Action Plan Review Needed",
    message:
      "Please review the pending action plan for addressing team feedback.",
    timestamp: "1 week ago",
    read: true,
    type: "performance",
  },
  {
    id: 9,
    title: "HR Policy Acknowledgment",
    message:
      "Reminder: Please acknowledge the updated HR policies by the end of the week.",
    timestamp: "2 days ago",
    read: false,
    type: "info",
  },
  {
    id: 10,
    title: "System Downtime Notification",
    message:
      "Scheduled system maintenance will occur this Saturday from 12:00 AM to 4:00 AM.",
    timestamp: "6 hours ago",
    read: false,
    type: "warning",
  },
  {
    id: 11,
    title: "Feedback Submission Reminder",
    message: "Your feedback on the recent survey is due by tomorrow evening.",
    timestamp: "4 hours ago",
    read: false,
    type: "info",
  },
  {
    id: 12,
    title: "New Training Module Released",
    message:
      "A new training module on workplace safety has been added to your dashboard.",
    timestamp: "10 days ago",
    read: true,
    type: "success",
  },
  {
    id: 13,
    title: "Holiday Announcement",
    message: "The office will be closed for the holidays from Dec 24 to Jan 1.",
    timestamp: "2 weeks ago",
    read: true,
    type: "announcement",
  },
  {
    id: 14,
    title: "System Alert: High CPU Usage",
    message: "A spike in CPU usage has been detected on server 14.",
    timestamp: "Just now",
    read: false,
    type: "error",
  },
  {
    id: 15,
    title: "Annual Performance Review",
    message: "Your annual performance review has been scheduled.",
    timestamp: "3 weeks ago",
    read: true,
    type: "event",
  },
];
;

export default function Notification() {
  const dispatch = useDispatch()
  const notificationsState = useSelector((data) => data.dashboard.notifications);

  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(sampleNotificationsData);
  const [filter, setFilter] = useState("all");

  const toggleList = () => setOpen(!open);

  const filteredNotifications = notifications.filter((notification) => {
    if (filter === "all") return true;
    if (filter === "read") return notification.read;
    if (filter === "unread") return !notification.read;
    return true;
  });

  const markAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((notif) => (notif.id === id ? { ...notif, read: true } : notif))
    );
  };

  const deleteNotification = (id) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const markAllAsRead = () => {
    setNotifications((prev) => prev.map((notif) => ({ ...notif, read: true })));
  };

  const openExternalEventsModal = () => {
    dispatch({ type: SET_EXTERNAL_EVENTS_MODAL, payload: true })
  }

  return (
    <>
      <div style={{ position: "relative", margin: "0 1rem" }}>
        <Badge
          badgeContent={notifications.filter((n) => !n.read).length}
          color="secondary"
        >
          <NotificationsNoneIcon
            onClick={toggleList}
            style={{ color: "#484848", cursor: "pointer" }}
          />
        </Badge>
        {open && (
          <div
            className="notification-panel"
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width: "400px",
                height: "500px",
                background: "#fff",
                right: "0",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "10px",
              }}
            >
              <CloseIcon
                aria-label="Close Notifications"
                onClick={() => setOpen(false)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "15px",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "#484848",
                  }}
                >
                  Notifications
                </Typography>
                <Button
                  onClick={markAllAsRead}
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #D06283",
                    color: "#D06283",
                    textTransform: "none",
                    position: "relative",
                    right: "30px"
                  }}
                >
                  Mark All as Read
                </Button>
              </div>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {["all", "read", "unread"].map((f) => (
                  <Button
                    key={f}
                    onClick={() => setFilter(f)}
                    style={{
                      textTransform: "capitalize",
                      fontWeight: filter === f ? "bold" : "normal",
                      color: filter === f ? "#3f51b5" : "#484848",
                      padding: "0"

                    }}
                  >
                    {f.charAt(0).toUpperCase() + f.slice(1)}
                  </Button>
                ))}
              </Box>
              <hr />
              <Box
                style={{
                  maxHeight: "400px",
                  borderRadius: "8px",
                  overflowY: "scroll",
                }}
              >
                {filteredNotifications.length === 0 ? (
                  <Typography
                    style={{
                      color: "#999",
                      textAlign: "center",
                      padding: "2rem",
                    }}
                  >
                    No notifications to display
                  </Typography>
                ) : (
                  filteredNotifications.map((notification) => (
                    <Box
                      onClick={() => { notification.type === "external-events" && openExternalEventsModal() }}
                      key={notification.id}
                      minHeight="70px"
                      style={{
                        backgroundColor: notification.read ? "#e0e0e0" : "#fff",
                        padding: "0.5rem",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#484848",
                                textAlign: "left",
                                margin: "0 0.5rem"
                              }}
                            >
                              {notification.title}
                            </Typography>
                            <Box marginLeft="0.5rem">
                              <Typography
                                style={{
                                  fontSize: "0.9rem",
                                  color: "#484848", textAlign: "left"
                                }}
                              >
                                {notification.message}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "0.8rem",
                                  color: "#999", textAlign: "left"
                                }}
                              >
                                {notification.timestamp}
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                        <Box display="flex" alignItems="center">
                          {!notification.read && (
                            <Tooltip title="Mark as Read">
                              <IconButton
                                onClick={() => markAsRead(notification.id)}
                              >
                                <CheckIcon style={{ color: "#3f51b5" }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => deleteNotification(notification.id)}
                            >
                              <DeleteIcon style={{ color: "#f50057" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
