import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  GET_ACTIVE_ENGAGEMENT_TOPICS,
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_THRESHOLD,
  SAVE_ACTIVE_ENGAGEMENT_TOPICS,
  SAVE_ENGAGEMENT_THRESHOLD,
} from "../../../../../redux/Dashboard/Dashboard.types";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const items = [
  "Accomplishment",
  "Autonomy & Empowerment",
  "Company Benefits",
  "Career Development & Growth",
  "Coaching & Mentorship",
  "Collaboration",
  "Commitment",
  "Communication",
  "Compensation & Pay Scale",
  "Diversity, Equity & Inclusion",
  "Freedom of Opinion",
  "Job Satisfaction",
  "Leadership",
  "Management Support",
  "Meaningful Work",
  "Mental Health",
  "Motivation",
  "Company Culture",
  "Passion for the Mission",
  "Peer Relationships",
  "Recognition",
  "Sense of Purpose",
  "Strategy",
  "Workload",
  "Workplace Environment",
];

function TopicModal({ handleClose }) {
  const dispatch = useDispatch();
  const insightThreshold = useSelector(
    (data) => data.dashboard.insightThreshold
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const activeEngagementTopics = useSelector(
    (data) => data.dashboard.activeEngagementTopics
  );

  const [checkedItems, setCheckedItems] = useState(
    items.reduce(
      (acc, item) => ({
        ...acc,
        [item]: true,
      }),
      {}
    )
  );

  useEffect(() => {
    setCheckedItems(
      items.reduce(
        (acc, item) => ({
          ...acc,
          [item]: activeEngagementTopics.engageTopicList
            ? activeEngagementTopics.engageTopicList.includes(item)
            : false,
        }),
        {}
      )
    );
  }, [activeEngagementTopics]);

  const handleChange = (event) => {
    const updatedCheckedItems = {
      ...checkedItems,
      [event.target.name]: event.target.checked,
    };

    setCheckedItems(updatedCheckedItems);
  };

  const handleSave = () => {
    dispatch({
      type: SAVE_ACTIVE_ENGAGEMENT_TOPICS,
      payload: {
        topicList: Object.keys(checkedItems).filter((key) => checkedItems[key]),
      },
    });
    handleClose();
  };

  //   useEffect(() => {
  //     setTopicEngageThreshold(engagementThreshold.topic);
  //     setTextEngageThreshold(engagementThreshold.text);
  //     setUserEngageThreshold(engagementThreshold.user);
  //     setSurveyEnagegThreshold(engagementThreshold.survey);
  //   }, [engagementThreshold]);

  useEffect(() => {
    dispatch({ type: GET_ACTIVE_ENGAGEMENT_TOPICS });
  }, []);
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      Select Topics to Include in Engagement Score
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        {items.sort().map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkedItems[item]}
                onChange={handleChange}
                name={item}
              />
            }
            label={item}
          />
        ))}
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default TopicModal;
