import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import axios from "axios";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import RenameModal from "../popup/EditModal";
import Modal from "@mui/material/Modal";
import { demoFocusChat } from "../demoData";
import { useLocation, useHistory } from "react-router-dom";
import ChatTranscript from "../ChatTranscript";

function FocusChatRes({ focusChatID }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const base_url = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const focusChats = isDemo
    ? demoFocusChat
    : useSelector((data) => data.dashboard.focusChats);
  const location = useLocation();

  const currentFocusGroup = focusChats.filter(
    (item) => item._id === location.pathname.split("/")[location.pathname.split("/").length - 1]
  )[0];
  const [reportContent, setReportContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  console.log("currentFocusGroup", currentFocusGroup)
  return (
    <Box style={{ margin: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          variant="outlined"
          style={{
            padding: "0.5rem 0",
            border: "2px solid #D06283",
            color: "#D06283",
            margin: "1rem 0",
          }}
        >
          <ArrowBackIcon />
        </Button>
      </Box>
      <Box
        sx={{
          padding: "1rem 1rem",
          backgroundColor: "#f9f9f9",
          border: "1px solid #dddddd",
          borderRadius: "4px",
          margin: " 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ width: "70%" }}>
          {currentFocusGroup?.adminView && (
            <Typography
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {currentFocusGroup?.adminView?.name
                ? currentFocusGroup?.adminView?.name
                : currentFocusGroup?.topic}
            </Typography>
          )}
          {
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
                textAlign: "left",
              }}
            >
              {/* {`${currentSurvey.section}: ${currentSurvey.change}`} */}

              {currentFocusGroup?.anonymous ? "Anonymous" : "Not Anonymous"}
            </Typography>
          }
          {currentFocusGroup?.adminView?.targetGroup && (
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
                textAlign: "left",
              }}
            >
              Target Group: {currentFocusGroup?.adminView?.targetGroup}
            </Typography>
          )}

          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            Participants: {6}
          </Typography>

          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            Topics: {currentFocusGroup?.discussionTopics?.join(", ")}
          </Typography>
        </Box>
        <Box style={{ width: "30%" }}>
          <Typography style={{ textAlign: "right", fontSize: "1.1rem" }}>
            Date/Time:{" "}
            {moment(currentFocusGroup?.startTime).format("MMM DD yyyy HH:MM")}
          </Typography>

          <Typography style={{ textAlign: "right", fontSize: "1.1rem" }}>
            Duration:{" "}
            {moment(currentFocusGroup?.endTime).diff(
              moment(currentFocusGroup?.startTime),
              "minutes"
            )}{" "}
            Minutes
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "end",
          position: "relative",
          top: "-40px",
          left: "-15px",
        }}
      >
        <Box style={{ margin: "0 1rem" }}>
          <Stack direction="row" spacing={1}>
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  Like
                </span>
              }
            >
              <ThumbUpOffAltIcon
                style={{
                  fontSize: "1rem",
                  color: "#484848",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  Dislike
                </span>
              }
            >
              <ThumbDownOffAltIcon
                style={{
                  fontSize: "1rem",
                  color: "#484848",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{ marginTop: "0rem", display: "flex", justifyContent: "center" }}
      >
        <Box
          style={{
            borderRadius: "4px",
            margin: "0",
            textAlign: "left",
            padding: " 0.5rem 0",
          }}
        >
          {currentFocusGroup?.report && <Box
            style={{
              background: "#f9f9f9",
              border: "1px solid #dddddd",
              borderRadius: "4px",
              margin: "0 0",
              textAlign: "left",
              padding: " 0.5rem 1rem",
            }}
          >

            <Markdown>{currentFocusGroup?.report}</Markdown>
          </Box>}
        </Box>
      </Box>
      <Box
        sx={{ marginTop: "0rem", display: "flex", justifyContent: "center" }}
      >
        <Box
          style={{
            borderRadius: "4px",
            margin: "0",
            textAlign: "left",
            padding: " 0.5rem 0",
          }}
        >
          {currentFocusGroup?.conversations && <Box
            style={{
              background: "#f9f9f9",
              border: "1px solid #dddddd",
              borderRadius: "4px",
              margin: "0 0",
              textAlign: "left",
              padding: " 0.5rem 1rem",
            }}
          ><Typography
            variant="h2"
            style={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "left", margin: "1rem 0" }}
          >
              Chat Transcript
            </Typography>
            {currentFocusGroup?.conversations.map((item, idx) => (
              <Box>
                <Typography
                  variant="h2"
                  style={{ fontSize: "1.2rem", fontWeight: "bold", textAlign: "left", margin: "1rem 0" }}
                >
                  {`Chat ${idx + 1}`}
                </Typography>
                <ChatTranscript messages={item.messages} />
              </Box>))}
          </Box>}
        </Box>
      </Box>

      <Modal
        open={renameModalOpen}
        onClose={() => {
          setRenameModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RenameModal
          focusGroup={currentFocusGroup}
          handleClose={() => {
            setRenameModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default FocusChatRes;
