/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, createRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lazy } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import _ from "lodash";
const HateSpeechTrend = lazy(() => import("../HateSpeechTrend"));
import {
  demoData,
  generateHateSpeechData,
  generatePositiveCultureData,
  generateNegativeCultureData,
  generateInsultData,
  generateSexualData,
  generateRacismData,
} from "../demoData";
import SplitButton from "../../../common/SplitButton";
import PositiveConfigModal from "./popup/PositiveConfigModal";
import NegativeConfigModal from "./popup/NegativeConfigModal";
import OverallCultureMeter from "./Overall/OverallCultureMeter";
import OverallCultureTrend from "./Overall/OverallCultureTrend";
import OverallCultureComparison from "./Overall/OverallCultureComparison";
import SubCultureTrend from "./SubCulture/Trend";
import SubCultureCompare from "./SubCulture/Comparison";
import SubCultureMeter from "./SubCulture/Meter";


function CulturalOverview({
  currentWorkflowID,
  currentFilter,
  moraleCompareOption,
  setMoraleCompareOption,
  customDate,
}) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const cultureTopicsToShow = useSelector(
    (data) => data.dashboard.cultureTopicsToShow
  );
  const [isZoom, setIsZoom] = useState(false);
  const [selectedPositive, setSelectedPositive] = useState(0);
  const [positiveConfigModal, setPositiveConfigModal] = useState(false);
  const [negativeConfigModal, setNegativeConfigModal] = useState(false);
  const [moraleLineToCompare, setMoraleLineToCompare] = useState([]);

  const moraleScore = isDemo ? demoData : demoData;

  // useEffect(() => {

  // }, [selectedPositive]);

  const positiveCultureData = isDemo
    ? generatePositiveCultureData(currentFilter, customDate)
    : useSelector((data) => data.dashboard.positiveCultureData);

  const negativeCultureData = isDemo
    ? generateNegativeCultureData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.negativeCultureData);

  const hateSpeechData = isDemo
    ? generateHateSpeechData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.hateSpeechData);

  const insultData = isDemo
    ? generateInsultData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.insultData);

  const sexualHarassmentData = isDemo
    ? generateSexualData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.sexualHarassmentData);

  const racismData = isDemo
    ? generateRacismData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.racismData);

  return (
    <div id="capture-culture">
      <div style={{ margin: "0 " }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            margin: "0rem 0 1.5rem 0.8rem",
          }}
        >
          <SplitButton
            options={["Overall", ...cultureTopicsToShow]}
            handleChange={setSelectedPositive}
          />
          <SettingsIcon
            onClick={() => {
              setPositiveConfigModal(true);
            }}
            style={{
              color: "#727272",
              cursor: "pointer",
              zIndex: "400",
              position: "absolute",
              right: "65px",
              top: "25px",
            }}
          />
        </div>
        {selectedPositive === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Suspense fallback={<p>loading</p>}>
                <OverallCultureMeter
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Suspense fallback={<p>loading</p>}>
                <OverallCultureTrend
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                  isZoom={isZoom}
                  currentFilter={currentFilter}
                  customDate={customDate}
                />
              </Suspense>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <Suspense fallback={<p>loading</p>}>
                <OverallCultureComparison
                  currentWorkflowID={currentWorkflowID}
                  currentFilter={currentFilter}
                  customDate={customDate}
                />
              </Suspense>
            </Grid>
          </Grid>
        )}
        {selectedPositive !== 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Suspense fallback={<p>loading</p>}>
                <SubCultureMeter
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                  subculture={cultureTopicsToShow[selectedPositive]}
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Suspense fallback={<p>loading</p>}>
                <SubCultureTrend
                  currentWorkflowID={currentWorkflowID}
                  positiveCultureData={positiveCultureData}
                  isZoom={isZoom}
                  currentFilter={currentFilter}
                  customDate={customDate}
                  subculture={cultureTopicsToShow[selectedPositive]}
                />
              </Suspense>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <Suspense fallback={<p>loading</p>}>
                <SubCultureCompare
                  currentWorkflowID={currentWorkflowID}
                  currentFilter={currentFilter}
                  customDate={customDate}
                  subculture={cultureTopicsToShow[selectedPositive]}
                />
              </Suspense>
            </Grid>
          </Grid>
        )}
      </div>

      <Modal
        open={positiveConfigModal}
        onClose={() => {
          setPositiveConfigModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PositiveConfigModal
          handleClose={() => {
            setPositiveConfigModal(false);
          }}
        />
      </Modal>
      <Modal
        open={negativeConfigModal}
        onClose={() => {
          setNegativeConfigModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <NegativeConfigModal
          handleClose={() => {
            setNegativeConfigModal(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default CulturalOverview;
