import { Box } from "@material-ui/core";
import React, {
  useEffect,
  useState,
  createRef,
  Suspense,
  useMemo,
} from "react";
import Grid from "@mui/material/Grid";
import UserMeter from "./UserMeter";
import EnagagementUsers from "../EngagementUsers";
import UserCompare from "./UserCompare";
import TextMeter from "./TextMeter";
import EnagagementTexts from "../EngagementTexts";
import TextCompare from "./TextCompare";
import OverallEngagementMeter from "./OverallEngagementMeter";
import OverallEngagementTrend from "./OverallEngagementTrend";
import OverallEngagementComparison from "./OverallEngagementComparison";
import SplitButton from "../../../common/SplitButton";
import TopicEngagementMeter from "./TopicEngagementMeter";
import TopicEngagementTrend from "./TopicEngagementTrend";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "@mui/material/Modal";
import ConfigModal from "./popup/ConfigModal";
import TopicModal from "./popup/TopicModal";
import { GET_ACTIVE_ENGAGEMENT_TOPICS } from "../../../../redux/Dashboard/Dashboard.types";
import { useDispatch, useSelector } from "react-redux";
import { generateMoraleScore } from "../demoData";
import MoraleCompare from "../MoraleCompare";

const topics = [
  { label: "All", value: "all", mapping: "All" },
  {
    label: "Accomplishment",
    value: "accomplishment",
    mapping: "Accomplishment",
  },
  {
    label: "Autonomy & Empowerment",
    value: "autonomyAndEmpowerment",
    mapping: "Autonomy and Empowerment",
  },
  {
    label: "Career Development & Growth",
    value: "careerDevelopmentAndGrowth",
    mapping: "Career Development and Growth",
  },
  {
    label: "Coaching & Mentorship",
    value: "coachingAndMentorship",
    mapping: "Coaching and Mentorship",
  },
  { label: "Collaboration", value: "collaboration", mapping: "Collaboration" },
  { label: "Commitment", value: "commitment", mapping: "Commitment" },
  { label: "Communication", value: "communication", mapping: "Communication" },
  {
    label: "Company Benefits",
    value: "companyBenefits",
    mapping: "Company Benefits",
  },
  {
    label: "Company Culture",
    value: "companyCulture",
    mapping: "Company Culture",
  },
  {
    label: "Compensation & Pay Scale",
    value: "compensationAndPayScale",
    mapping: "Compensation and PayScale",
  },
  {
    label: "Diversity, Equity & Inclusion",
    value: "diversityEquityAndInclusion",
    mapping: "Diversity Equity and Inclusion",
  },
  {
    label: "Freedom of Opinion",
    value: "freedomOfOpinion",
    mapping: "Freedom of Opinion",
  },
  {
    label: "Job Satisfaction",
    value: "jobSatisfaction",
    mapping: "Job Satisfaction",
  },
  { label: "Leadership", value: "leadership", mapping: "Leadership" },
  {
    label: "Management Support",
    value: "managementSupport",
    mapping: "Management Support",
  },
  {
    label: "Meaningful Work",
    value: "meaningfulWork",
    mapping: "Meaningful Work",
  },
  { label: "Mental Health", value: "mentalHealth", mapping: "Mental Health" },
  { label: "Motivation", value: "motivation", mapping: "Motivation" },
  {
    label: "Passion for the Mission",
    value: "passionForTheMission",
    mapping: "Passion for the Mission",
  },
  {
    label: "Peer Relationships",
    value: "peerRelationships",
    mapping: "Peer Relationships",
  },
  { label: "Recognition", value: "recognition", mapping: "Recognition" },
  {
    label: "Sense of Purpose",
    value: "senseOfPurpose",
    mapping: "Sense of Purpose",
  },
  { label: "Strategy", value: "strategy", mapping: "Strategy" },
  { label: "Workload", value: "workload", mapping: "Workload" },
  {
    label: "Workplace Environment",
    value: "workplaceEnvironment",
    mapping: "Workplace Environment",
  },
];

function EngagementBlock({
  currentWorkflowID,
  engagementData,
  currentFilter,
  engagementTopicTrendData,
  customDate,
}) {
  const dispatch = useDispatch();
  const [selectedTopic, setTopicSelected] = useState(0);
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [topicModalOpen, setTopicModalOpen] = useState(false);
  const activeEngagementTopics = useSelector(
    (data) => data.dashboard.activeEngagementTopics
  );
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const stateMoraleScore = useSelector((state) => state.dashboard.moraleScore);


  const moraleScore = useMemo(() => {
    return isDemo
      ? generateMoraleScore(currentFilter, customDate)
      : stateMoraleScore;
  }, [isDemo, currentFilter, customDate, stateMoraleScore]);


  useEffect(() => {
    dispatch({ type: GET_ACTIVE_ENGAGEMENT_TOPICS });
  }, []);

  const sortTopics = (topics) => {
    const allTopic = topics.find((topic) => topic.value === "all"); // Find the "All" topic
    const sortedOtherTopics = topics
      .filter((topic) => topic.value !== "all") // Exclude "All" from the sorting
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label

    return [allTopic, ...sortedOtherTopics]; // Place "All" on top
  };

  console.log(sortTopics(topics).map((item) => item.label), activeEngagementTopics)
  return (
    <Box>
      <div style={{ margin: "0 " }}>
        <SettingsIcon
          onClick={() => {
            setConfigModalOpen(true);
          }}
          style={{
            color: "#727272",
            cursor: "pointer",
            zIndex: "400",
            position: "absolute",
            right: "60px",
            top: "25px",
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Suspense fallback={<p>loading</p>}>
              <OverallEngagementMeter
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
                currentFilter={currentFilter}
              />
            </Suspense>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Suspense fallback={<p>loading</p>}>
              <OverallEngagementTrend
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
              />
            </Suspense>
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <Suspense fallback={<p>loading</p>}>
              <OverallEngagementComparison
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
                moraleData={moraleScore}
                currentFilter={currentFilter}
              />

            </Suspense>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          background: "#fff",
          margin: "1rem 0 2rem",
          padding: "1rem",
          borderRadius: "12px",
        }}
      >
        <div style={{ background: "#F6F7FC", padding: "1rem 1rem 1rem 2rem" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "2rem 1rem 1.5rem 0rem",
            }}
          >
            <SplitButton
              options={["All",...sortTopics(topics).map((item) => item.label).filter((item) => activeEngagementTopics.engageTopicList.includes(item))]}
              handleChange={setTopicSelected}
            />
            <SettingsIcon
              onClick={() => {
                setTopicModalOpen(true);
              }}
              style={{
                color: "#727272",
                cursor: "pointer",
                zIndex: "400",
                position: "relative",
                right: "20px",
              }}
            />
          </div>
          <div style={{ margin: "0 " }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Suspense fallback={<p>loading</p>}>
                  <TopicEngagementMeter
                    currentWorkflowID={currentWorkflowID}
                    engagementData={engagementTopicTrendData}
                    currentFilter={currentFilter}
                    topic={topics[selectedTopic]}
                  />
                </Suspense>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Suspense fallback={<p>loading</p>}>
                  <TopicEngagementTrend
                    currentWorkflowID={currentWorkflowID}
                    engagementData={engagementTopicTrendData}
                    topic={topics[selectedTopic]}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ margin: "0 " }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Suspense fallback={<p>loading</p>}>
              <UserMeter
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
                currentFilter={currentFilter}
              />
            </Suspense>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Suspense fallback={<p>loading</p>}>
              <EnagagementUsers
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
              />
            </Suspense>
          </Grid>
        </Grid>
      </div>

      <div style={{ margin: "0 " }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Suspense fallback={<p>loading</p>}>
              <TextMeter
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
                currentFilter={currentFilter}
              />
            </Suspense>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Suspense fallback={<p>loading</p>}>
              <EnagagementTexts
                currentWorkflowID={currentWorkflowID}
                engagementData={engagementData}
              />
            </Suspense>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfigModal
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={topicModalOpen}
        onClose={() => {
          setTopicModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <TopicModal
          handleClose={() => {
            setTopicModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default EngagementBlock;
