import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../styles/global.css";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../Context/UserContext";
import { DEMO_LOGIN, LOGIN, SAVE_DEMO_ID } from "../redux/Login/Login.types";
import axios from "axios";
import Navbar from "../Components/Navbar";
const DemoLoginPage = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState("");
  const auth = useContext(AuthContext);

  const login = useSelector((data) => data.login);

  const loginResp = {
    data: {
      first_name: "Demo",
      last_name: "User",
      email: "demo@scanta.io",
      _id: "673c579ab0d8f31f385053e7",
      company_name: "Scanta",
      phone_number: "919999999999",
      country: "India",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjFlNTlhYTgxNjFmOWQwMDE0OWYzNzlkIiwiZW1haWwiOiJhZGFyc2hAc2NhbnRhLmlvIiwicGVybWlzc2lvbnMiOnsib3ZlcnZpZXciOiJXIiwibG9naWMiOiJXIiwiaW52ZXN0aWdhdGlvbiI6IlcifSwicm9sZSI6ImFkbWluIiwicGFyZW50IjpudWxsLCJpYXQiOjE2NTUxMDgyNzgsImV4cCI6MTY1NTE1MTQ3OH0.JYb7T-ISgujnIzdqgNWgtiFi9wk4VyRzDw9GifOMmZE",
    },
  };
  const handleDemoLogin = (e) => {
    e.preventDefault();
    if (email.length > 3) {

      let user = {}

      if (process.env.REACT_APP_BASE_URL.includes("staging")) {
        localStorage.setItem("isDemo", JSON.stringify(true));
        localStorage.setItem("nightMode", true);
        user = { email: "dirk@trupulse.ai", password: "dirk" };
        dispatch({ type: LOGIN, payload: user });
      }
      else {
                // user = { email: "demo@infosys.com", password: "demo" };

        localStorage.setItem("demoMode", JSON.stringify(true));
        localStorage.setItem("nightMode", true);
        dispatch({ type: DEMO_LOGIN, payload: loginResp });

      }

      // dispatch({ type: LOGIN, payload: user });
      !email.includes("@scanta.io") && !email.includes("@trupulse.ai") && dispatch({ type: SAVE_DEMO_ID, payload: { email: email } });
    } else {
      setAlert("Please enter a valid email address!");
    }
  };
  const storedData = JSON.parse(localStorage.getItem("userData"));
  const history = useHistory();

  useEffect(() => {
    if (
      storedData &&
      storedData.usertoken &&
      new Date(storedData.expiration) > new Date()
    ) {
      history.push("/dashboard");
    }
  });

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("userData"));
    if (login.userLoggedIn) {
      localStorage.setItem("auth-token", login.loginData.data.token);
      auth.login(login.loginData.data._id, login.loginData.data.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.userLoggedIn]);

  return (
    <div className="loginPage">
      <div style={{ width: "100%" }}>
        <Navbar isLoggedIn={false} />
      </div>
      <div className="formLoginContainer">
        <div className="formLogo">
          <img
            src="/Logo-3x.png"
            alt="logo"
            width="150px"
            style={{
              padding: "0.5rem 0 0",
            }}
          />
        </div>
        <p className="formLabels" style={{ textAlign: "center" }}>
          Enter your email to log into the TruPulse Live Demo. Walk through the
          product features at your own pace to discover the future of AI based
          People Analytics!
        </p>
        <form className="loginForm">
          <div className="inputContainer">
            <label className="formLabels">Email</label>
            <input
              type="text"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter your email"
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              name="uname"
              required
            />
          </div>
          {alert.length > 0 && (
            <div className="errorMessage" style={{ padding: "0" }}>
              {alert}
            </div>
          )}
          <div
            role="presentation"
            className="submitButton"
            style={{ margin: "1rem 0 0.5rem" }}
            onClick={handleDemoLogin}
          >
            <div className="buttonText">Start Demo</div>
          </div>
          <Link to="/" className="backBtn">
            <div role="presentation" style={{ margin: "0rem 0" }}>
              <div className="buttonText" style={{ color: "#001011" }}>
                Back
              </div>
            </div>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default DemoLoginPage;
