import { useParams, useHistory } from "react-router-dom";
import SurveyList from "./Survey/List";
import FocusGroupList from "./FocusGroup/List";
import FocusChatList from "./FocusChat/List";

function FeedbackList() {
    const { category, type } = useParams();
    const history = useHistory();

    const handleItemClick = (id) => {
        history.push(`/dashboard/feedback/${category}/${type}/${id}`);
    };

    return (
        <div>
            {category === "survey" && <SurveyList type={type} />}
            {category === "focus-group" && <FocusGroupList type={type} />}
            {category === "focus-chat" && <FocusChatList type={type} />}

        </div>
    );
}

export default FeedbackList;
