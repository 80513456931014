import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom"; // ✅ React Router v5
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import PollIcon from "@mui/icons-material/Poll";
import GroupsIcon from "@mui/icons-material/Groups";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

function SidePanel({ setSelectedTab }) {
  const [surveyOpen, setSurveyOpen] = useState(false);
  const [focusChatOpen, setFocusChatOpen] = useState(false);
  const [focusGroupOpen, setFocusGroupOpen] = useState(false);

  const history = useHistory(); // ✅ Corrected useHistory
  const location = useLocation(); // ✅ Get current URL

  // ✅ Track active tab based on URL
  useEffect(() => {
    if (location.pathname.includes("/dashboard/feedback/focus-chat")) {
      setFocusChatOpen(true);
    } else if (location.pathname.includes("/dashboard/feedback/focus-group")) {
      setFocusGroupOpen(true);
    } else if (location.pathname.includes("/dashboard/feedback/survey")) {
      setSurveyOpen(true);
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100px",
        backgroundColor: "#f2f2f2",
        paddingTop: "2rem",
      }}
    >
      <List component="nav">
        {/* Focus Chat */}
        <ListItemButton onClick={() => setFocusChatOpen(!focusChatOpen)}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="1:1 Discussions" />
          {focusChatOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={focusChatOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => history.push("/dashboard/feedback/focus-chat/insight")}
              selected={location.pathname.includes("/dashboard/feedback/focus-chat/insight")}
            >
              <ListItemText primary="Available Discussions" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => history.push("/dashboard/feedback/focus-chat/insight/results")}
              selected={location.pathname.includes("/dashboard/feedback/focus-chat/insight/results")}
            >
              <ListItemText primary="Results" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Focus Group */}
        <ListItemButton onClick={() => setFocusGroupOpen(!focusGroupOpen)}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Focus Groups" />
          {focusGroupOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={focusGroupOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => history.push("/dashboard/feedback/focus-group/insight")}
              selected={location.pathname.includes("/dashboard/feedback/focus-group/insight")}
            >
              <ListItemText primary="Available Focus Groups" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => history.push("/dashboard/feedback/focus-group/insight/results")}
              selected={location.pathname.includes("/dashboard/feedback/focus-group/insight/results")}
            >
              <ListItemText primary="Results" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Survey */}
        <ListItemButton onClick={() => setSurveyOpen(!surveyOpen)}>
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          <ListItemText primary="Surveys" />
          {surveyOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={surveyOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => history.push("/dashboard/feedback/survey/insight")}
              selected={location.pathname.includes("/dashboard/feedback/survey/insight")}
            >
              <ListItemText primary="Available Surveys" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => history.push("/dashboard/feedback/survey/insight/results")}
              selected={location.pathname.includes("/dashboard/feedback/survey/insight/results")}
            >
              <ListItemText primary="Results" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Box>
  );
}

export default SidePanel;
