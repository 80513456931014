import { useParams } from "react-router-dom";
import Survey from "./Survey";
import BaselineSurvey from "./Survey/BaselineSurvey";
import FocusChat from "./FocusChat/FocusChat";
import FocusGroup from "./FocusGroup/FocusGroup";

function FeedbackDetails() {
    const { category, type, id } = useParams();

    return (
        <div>
            {category === "survey" && type === "insight" && <Survey surveyID={id} />}
            {category === "survey" && type === "baseline" && <BaselineSurvey surveyID={id} />}

            {category === "focus-group" && type === "insight" && <FocusGroup focusGroupID={id} />}
            {category === "focus-group" && type === "baseline" && <BaselineSurvey focusGroupID={id} />}

            {category === "focus-chat" && type === "insight" && <FocusChat focusChatID={id} />}

        </div>
    );
}

export default FeedbackDetails;
