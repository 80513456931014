import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { SAVE_DEMO_ANNOTATION } from "../../redux/Dashboard/Dashboard.types";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NewspaperIcon from "@mui/icons-material/Newspaper";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
};

const demoEvents = [
    {
        "label": "Bankruptcy Filing Warning",
        "title": "Company Considers Bankruptcy Amid Financial Struggles",
        "description": "Executives are exploring potential bankruptcy filing due to mounting debts and declining revenues. Investors and employees brace for possible restructuring.",
        "source": "https://www.financeinsider.com/scanta-inc-bankruptcy-warning",
        "time": "2025-03-10"
    },
    {
        "label": "CEO Resignation Shock",
        "title": "Unexpected CEO Departure Raises Leadership Concerns",
        "description": "In a surprise move, the CEO has resigned without prior notice, leaving leadership in question as the board scrambles to find a replacement.",
        "source": "https://www.corporatenews.com/unexpected-ceo-departure",
        "time": "2025-03-12"
    },
    {
        "label": "Mass Layoffs Announced",
        "title": "Major Job Cuts Impact Multiple Departments",
        "description": "A restructuring effort has led to layoffs affecting thousands of employees, citing economic pressures and operational challenges.",
        "source": "https://www.economytoday.com/massive-layoffs-announcement",
        "time": "2025-03-15"
    },
    {
        "label": "Merger Talks Uncertainty",
        "title": "Merger Speculations Spark Employee Concerns",
        "description": "Rumors of a potential merger with a competitor have raised anxieties about possible job cuts and structural changes in the organization.",
        "source": "https://www.businessinsider.com/merger-talks-employee-anxiety",
        "time": "2025-03-18"
    },
    {
        "label": "Data Breach Exposed",
        "title": "Cybersecurity Incident Compromises Customer Data",
        "description": "A recent breach has exposed sensitive customer information, raising concerns about security measures and regulatory repercussions.",
        "source": "https://www.cybersecuritynews.com/data-breach-disclosure",
        "time": "2025-03-20"
    },
    {
        "label": "Earnings Report Fallout",
        "title": "Weak Financial Performance Dampens Market Confidence",
        "description": "A disappointing quarterly earnings report has triggered a sharp stock decline, prompting concerns about the company's financial outlook.",
        "source": "https://www.financeupdates.com/weak-earnings-report",
        "time": "2025-03-22"
    },
    {
        "label": "Key Contract Loss",
        "title": "Loss of Major Contract Raises Financial Concerns",
        "description": "The company has lost a key multi-million-dollar contract, leading to questions about future revenue streams and long-term stability.",
        "source": "https://www.businessnews.com/key-contract-loss",
        "time": "2025-03-25"
    },
    {
        "label": "Executive Decision Backlash",
        "title": "Controversial Leadership Move Faces Internal Resistance",
        "description": "A recent executive decision has sparked backlash from employees, with growing concerns over its impact on company culture and operations.",
        "source": "https://www.corporatenews.com/executive-decision-backlash",
        "time": "2025-03-27"
    },
    {
        "label": "Union Strike Intensifies",
        "title": "Workforce Tensions Rise as Union Strike Escalates",
        "description": "Ongoing labor disputes have resulted in intensified strike action, disrupting operations and drawing public attention to employee demands.",
        "source": "https://www.industrystandard.com/union-strike-intensifies",
        "time": "2025-03-01"
    },
    {
        "label": "Regulatory Probe Launched",
        "title": "Government Investigation Sparks Uncertainty",
        "description": "A regulatory body has launched an investigation into certain business practices, raising concerns about compliance and potential penalties.",
        "source": "https://www.regulatorynews.com/probe-launched",
        "time": "2025-03-05"
    }
];



function ExternalEventsModal({ handleClose }) {
    const dispatch = useDispatch();
    const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
    const [selectedEvents, setSelectedEvents] = useState([]);

    const toggleSelection = (event) => {
        setSelectedEvents((prev) =>
            prev.includes(event) ? prev.filter((e) => e !== event) : [...prev, event]
        );
    };

    const handleSave = () => {
        if (selectedEvents.length === 0) return;
        
        const newEvents = selectedEvents.map((event) => ({
            _id: Math.floor(Math.random() * 90000) + 10000,
            name: event.label,
            description: event.description,
            date: moment(event.time, ["YYYY-MM-DD", "DD-MMM-YYYY"]).format("YYYY-MM-DD"),
            isVisible: true,
            color: "#153465",
            type:"news"
        }));

        dispatch({
            type: SAVE_DEMO_ANNOTATION,
            payload: [...calenderEvents, ...newEvents],
        });
        handleClose();
    };

    return (
        <Box sx={style}>
            <Typography variant="h6">News Events</Typography>
            <CloseIcon
                style={{ cursor: "pointer", position: "absolute", right: 20, top: 20 }}
                onClick={handleClose}
            />
            <Box style={{ maxHeight: "75vh", overflowY: "auto" }}>
                {demoEvents.map((item, index) => (
                    <Box key={index} style={{ margin: "0.5rem 0", border: "1px solid #a2a2a2", borderRadius: "8px", padding: "0.5rem", display: "flex" }}>
                        <Checkbox
                            checked={selectedEvents.includes(item)}
                            onChange={() => toggleSelection(item)}
                        />
                        <Box style={{ width: "92%" }}>
                            <Typography>{item.label}</Typography>
                            <Typography style={{ fontWeight: "600" }}>{item.title}</Typography>
                            <Typography style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {item.description}
                            </Typography>
                            <span style={{ fontSize: "0.9rem" }}>
                                <CalendarTodayIcon style={{ position: "relative", top: "2px", fontSize: "1rem" }} />
                                {moment(item.time, ["YYYY-MM-DD", "DD-MMM-YYYY"]).format("MM/DD/YYYY")}
                            </span>
                            <span style={{ fontSize: "0.9rem", marginLeft: "1rem" }}>
                                <NewspaperIcon style={{ position: "relative", top: "3px", fontSize: "1rem" }} />
                                <a href={item.source} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "#1976d2" }}>
                                    Source
                                </a>
                            </span>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Stack direction="row" spacing={2} justifyContent="end" style={{ marginTop: "1rem" }}>
                <Button variant="outlined" onClick={handleClose} style={{ textTransform: "capitalize" }}>
                    Close
                </Button>
                <Button
                    onClick={handleSave}
                    style={{ background: "#D06283", color: "#fff", textTransform: "capitalize" }}
                    variant="contained"
                    disabled={selectedEvents.length === 0}
                >
                    Add to Event Manager
                </Button>
            </Stack>
        </Box>
    );
}

export default ExternalEventsModal;
