import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import DoughnutChart from "./Doughnut";
// import HorizontalBarChart from "./HorizontalChart";
// import LineChart from "./LineChart";
// import VerticalBar from "./VerticalBar";
// import CircleProgress from "./GradientCircle";
// import "../../../styles/";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { toast } from "react-toastify";
import moment from "moment";

import {
  ADD_CALENDER_EVENT,
  GET_CALENDER_EVENTS,
  SAVE_DEMO_ANNOTATION,
  UPDATE_CALENDER_EVENT,
} from "../../../redux/Dashboard/Dashboard.types";
const bgColors = [
  "#153465",
  "#67160e",
  "#135714",
  "#341b4d",
  "#b45c18",
  "#cca529",
];
const UpdateAnnotation = ({ handleClose, setCurrentScreen, selectedEvent }) => {
  const dispatch = useDispatch();
  const calenderEventSaved = useSelector(
    (data) => data.dashboard.calenderEventSaved
  );
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventColor, setEventColor] = useState("#d06283");
  const [eventDate, setEventDate] = useState(new Date());
  const [isVisible, setIsVisible] = useState(true);
  const [eventType, setEventType] = useState("");
  useEffect(() => {
    const temp = calenderEvents.filter((item) => item._id === selectedEvent)[0];
    console.log(temp);
    setEventName(temp.name);
    setEventDescription(temp.description);
    setEventDate(temp.date);
    setEventColor(temp.color);
    setIsVisible(temp.isVisible);
    setEventType(temp.type);
  }, [selectedEvent]);

  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }

  function RGB2HEX(color) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
    const hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`;

    return hex;
  }

  const handleUpdateEvent = () => {
    const RGB = hexToRgb(eventColor);
    dispatch({
      type: UPDATE_CALENDER_EVENT,
      payload: {
        eventId: selectedEvent,
        name: eventName,
        description: eventDescription,
        date: eventDate,
        isVisible: isVisible,
        color: eventColor,
        type: eventType
      },
    });

    setTimeout(() => {
      if (calenderEventSaved) {
        toast.success("Event updated successfully");
        setCurrentScreen("list");
        dispatch({
          type: GET_CALENDER_EVENTS,
          payload: {},
        });
      }
    }, 2000);
  };

  const updateDemoCalender = () => {
    const RGB = hexToRgb(eventColor);
    const otherEvents = calenderEvents.filter(
      (item) => item._id !== selectedEvent
    );
    dispatch({
      type: SAVE_DEMO_ANNOTATION,
      payload: [
        ...otherEvents,
        {
          _id: selectedEvent,
          name: eventName,
          description: eventDescription,
          date: eventDate,
          isVisible: isVisible,
          color: eventColor,
          type: eventType
        },
      ],
    });
    setCurrentScreen("list");
    toast.success("Event updated successfully");
  };
  return (
    <div
      style={{
        height: "100%",
        margin: "1rem 0.5rem",
        padding: "1rem",
      }}
    >
      <div className="inputContainer">
        <label className="formLabels">Event Name</label>
        <input
          type="text"
          value={eventName}
          placeholder="Enter your event name"
          name="eventName"
          required
          onChange={(e) => {
            setEventName(e.target.value);
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="formLabels">Event Description</label>
        <input
          type="text"
          value={eventDescription}
          placeholder="Enter your event description"
          name="eventDescription"
          required
          onChange={(e) => {
            setEventDescription(e.target.value);
          }}
        />
      </div>
      <div className="inputContainer">
        <label className="formLabels">Event Date</label>
        <input
          type="date"
          value={moment(eventDate).format("YYYY-MM-DD")}
          placeholder="Enter your event date"
          name="eventDate"
          required
          onChange={(e) => {
            setEventDate(e.target.value);
          }}
        />
      </div>
      <div className="inputContainer">
        <label className="formLabels">Event Color</label>
        <div style={{ display: "flex" }}>
          {bgColors.map((item) => (
            <div
              style={{
                width: "20px",
                height: "20px",
                margin: "0 0.5rem",
                padding: "3px",
                borderRadius: "20px",
                border:
                  eventColor === item ? "3px solid #00f" : `3px solid #fff`,
              }}
            >
              <div
                onClick={() => {
                  setEventColor(item);
                }}
                style={{
                  width: "20px",
                  height: "20px",
                  // margin: "0 1rem",
                  background: item,
                  borderRadius: "20px",
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <div className="btnOptions" style={{ width: "100%" }}>
        <div
          className="cancelBtn"
          onClick={() => {
            setCurrentScreen("list");
          }}
        >
          <div className="rangeBtn">
            <ArrowBackIosIcon style={{ color: "#000" }} />
            <div className="btnText" style={{ color: "#000" }}>
              Back
            </div>
          </div>
        </div>
        <div
          className="submitBtn"
          onClick={isDemo ? updateDemoCalender : handleUpdateEvent}
        >
          <div className="rangeBtn">
            <BookmarkBorderIcon />
            <div className="btnText" type="submit">
              Update
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAnnotation;
