import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import ArchiveIcon from "@mui/icons-material/Archive";
import AddIcon from "@mui/icons-material/Add";
import InsightsIcon from "@mui/icons-material/Insights";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import RepeatIcon from "@mui/icons-material/Repeat";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import { useLocation } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { demoSurveys } from "../demoSurveys";
import Modal from "@mui/material/Modal";
import LaunchModal from "../Popups/LaunchModal";
import ArchiveModal from "../Popups/ArchiveModal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import CustomSurveyModal from "../Popups/CustomSurveyModal";
import { titleCase } from "../../../../utilities/dashboardUtils";
import ArchivedList from "./ArchivedList";
import CompletedList from "./CompletedList";
import SettingsModal from "../Popups/SettingsModal";
import BaselineList from "./BaselineList";
import FavoriteList from "./FavoriteList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSurveyList from "./CustomSurveyList";
import AssistantBox from "../../Overview/AssistantBox";

const tableHead = {
  surveyName: "Name",
  section: "Metric",
  statement: "Key Insight",
  tags: "Target Group",
  status: "Status",
  action: "Action",
};

const featureMapping = {
  moraleInsights: "Morale Score",
  userEngageInsights: "User Engagement Score",
  textEngageInsights: "Text Engagement Score",
  overallEngagementInsights: "Overall Engagement Score",
  retentionInsights: "Retention Risk",
  enpsInsights: "eNPS Score",
  topicEnpsInsights: "eNPS Engagement Topic Based Score",
  culture: "Culture",
  sexual_harassment: "Sexual Harassment",
  racism: "Racism",
  empathy: "Empathy",
  identity_hate: "Identity Hate",
  insult: "Insult",
  toxic_behavior: "Toxic Behaviour",
  gratitude_and_appreciation: "Gratitude and Appreciation",
  supportive_language: "Supportive Language",
  employee_recognition: "Employee Recognition",
  collaboration: "Collaboration",
  transparency: "Transparency",
  inclusive_language: "Inclusive Language",
  professionalism: "Professionalism",
  "work-life_balance": "Work-Life Balance",
  hate_speech: "Hate Speech",
  obscene: "Obscene",
  threat: "Threat",
  overall: "Overall Positive/Negative Culture",
};
const metricMapping = {
  morale: ["Morale Score", "Morale"],
  engagement: [
    "User Engagement",
    "User Engagement Score",
    "Overall Engagement Score",
    "Text Engagement",
    "Text Engagement Score",
  ],
  retention: ["Retention Risk"],
  eNPS: ["eNPS Engagement Topic Based Score", "eNPS Score"],
  topic: [],
  culture: [
    "Culture",
    "Overall Positive/Negative Culture",
    "Adaptability",
    "Celebrating Diversity",
    "Collaboration",
    "Community Involvement",
    "Constructive Feedback",
    "Creativity and Innovation",
    "Empathy",
    "Employee Recognition",
    "Gratitude and Appreciation",
    "Hate Speech",
    "Identity Hate",
    "Inclusive Language",
    "Insult",
    "Insults",
    "Obscene",
    "Open Communication",
    "Overall Positive/Negative Culture",
    "Personal Development",
    "Professionalism",
    "Racism",
    "Sexual Behaviour",
    "Sexual Harassment",
    "Supportive Language",
    "Team Celebrations",
    "Textual Insult",
    "Toxic",
    "Toxic Behaviour",
    "Transparency",
    "Wellness and Health",
    "Work-Life Balance",
  ],
};

function SurveyList({ setSeletedSurvey, type }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const location = useLocation();
  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const [currentMetric, setCurrentMetric] = useState("morale");

  const [configModalOpen, setConfigModalOpen] = useState(false);

  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState({});
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [collection, setCollection] = useState(
    cloneDeep(sortedData.slice(0, countPerPage))
  );
  const [filteredData, setFilteredData] = useState([]);

  const search = (query) => {
    const filteredData = sortedData.filter(
      (item) =>
        item._id?.toLowerCase().includes(query) ||
        item.surveyName?.toLowerCase().includes(query) ||
        item.status?.toLowerCase().includes(query) ||
        item.section?.toLowerCase().includes(query) ||
        item.statement?.toLowerCase().includes(query) ||
        item.insightID?.toLowerCase().includes(query)
    );
    setFilteredData(filteredData);
    setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    setCurrentPage(1);
  };

  useEffect(() => {
    setCollection(cloneDeep(sortedData.slice(0, countPerPage)));
  }, [sortedData]);

  useEffect(() => {
    let temp = surveyData
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .filter(
        (item) =>
          item.status !== "archived" &&
          item.surveyType === "insightsurvey" &&
          // new Date(item.expiryDate) > new Date() &&
          metricMapping[currentMetric].includes(item.section)
      );
    // item.status !== "archived" && new Date(item.expiryDate) > new Date()
    setSortedData(temp);
    setFilteredData(temp);
  }, [surveyData, currentMetric]);

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      search(value);
      // searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(filteredData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return keyD === "action" ? (
        <Stack direction="row" spacing={2} sx={{ margin: "2rem 1rem" }}>
          <Link
            to={`/dashboard/feedback/survey/insight/${key._id}`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #D06283",
                color: "#484848",
                textTransform: "capitalize",
              }}
            >
              Show
            </Button>
          </Link>
          {key.status !== "archived" ? (
            <Button
              onClick={() => {
                setArchiveModalOpen(true);
                setCurrentSurvey(key);
              }}
              variant="outlined"
              startIcon={<ArchiveIcon />}
              style={{
                border: "1px solid #D06283",
                color: "#484848",
                textTransform: "capitalize",
              }}
            >
              Archive
            </Button>
          ) : (
            <Button
              onClick={() => {
                setArchiveModalOpen(true);
                setCurrentSurvey(key);
              }}
              variant="outlined"
              startIcon={<ArchiveIcon sx={{ transform: "scaleY(-1)" }} />}
              style={{
                border: "1px solid #D06283",
                color: "#484848",
                textTransform: "capitalize",
              }}
            >
              Unarchive
            </Button>
          )}
          <Button
            onClick={() => {
              setLaunchModalOpen(true);
              setCurrentSurvey(key);
            }}
            variant="contained"
            endIcon={<SendIcon />}
            style={{
              background: "#D06283",
              textTransform: "capitalize",
              opacity:
                key.status === "running" || key.status === "archived"
                  ? "0.7"
                  : "1",
              pointerEvents:
                key.status === "running" || key.status === "archived"
                  ? "none"
                  : "",
            }}
          >
            Launch
          </Button>
        </Stack>
      ) : keyD === "statement" ? (
        <td key={i}>{`${key[keyD]} ID:${key["insightID"]}`}</td>
      ) : keyD === "tags" ? (
        <td key={i}>
          {key?.display?.target_group
            ? key?.display?.target_group
            : key[keyD] === ""
              ? "Overall"
              : titleCase(key[keyD])}
        </td>
      ) : keyD === "status" ? (
        <td key={i}>{titleCase(key[keyD])}</td>
      ) : keyD === "surveyName" ? (
        <td key={i}>{key?.display?.name ? key?.display?.name : key[keyD]}</td>
      ) : (
        <td key={i}>{key[keyD]}</td>
      );
    });

    return (
      <tr style={{ border: "1px solid #727272" }} key={index}>
        {columnData}
      </tr>
    );
  };
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  return (
    <Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 1rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            position: "relative",
            top: "30px",
            left: "20px",
          }}
        >
          <Link
            to={`/dashboard/feedback/survey/insight`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button

              variant={type === "insight" ? "contained" : "outlined"}
              startIcon={<InsightsIcon />}
              style={
                type === "insight"
                  ? {
                    background: "#D06283",
                    textTransform: "capitalize",
                    margin: "0 0.5rem",
                    fontSize: "1.1rem",
                  }
                  : {
                    border: "1px solid #D06283",
                    color: "#484848",
                    textTransform: "capitalize",
                    fontSize: "1.1rem",
                    margin: "0 0.5rem",
                  }
              }
            >
              Insight
            </Button>
          </Link>
          <Link
            to={`/dashboard/feedback/survey/baseline`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button

              variant={type === "baseline" ? "contained" : "outlined"}
              startIcon={<MonitorHeartIcon />}
              style={
                type === "baseline"
                  ? {
                    background: "#D06283",
                    textTransform: "capitalize",
                    margin: "0 0.5rem",
                    fontSize: "1.1rem",
                  }
                  : {
                    border: "1px solid #D06283",
                    color: "#484848",
                    textTransform: "capitalize",
                    fontSize: "1.1rem",
                    margin: "0 0.5rem",
                  }
              }
            >
              Baseline
            </Button>
          </Link>
          <Link
            to={`/dashboard/feedback/survey/recurring`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button

              variant={type === "recurring" ? "contained" : "outlined"}
              startIcon={<RepeatIcon />}
              style={
                type === "recurring"
                  ? {
                    background: "#D06283",
                    textTransform: "capitalize",
                    margin: "0 0.5rem",
                    fontSize: "1.1rem",
                  }
                  : {
                    border: "1px solid #D06283",
                    color: "#484848",
                    textTransform: "capitalize",
                    fontSize: "1.1rem",
                    margin: "0 0.5rem",
                  }
              }
            >
              Recurring
            </Button>
          </Link>
          <Link
            to={`/dashboard/feedback/survey/custom`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            {" "}
            <Button

              variant={type === "custom" ? "contained" : "outlined"}
              startIcon={<SmartButtonIcon />}
              style={
                type === "custom"
                  ? {
                    background: "#D06283",
                    textTransform: "capitalize",
                    margin: "0 0.5rem",
                    fontSize: "1.1rem",
                  }
                  : {
                    border: "1px solid #D06283",
                    color: "#484848",
                    textTransform: "capitalize",
                    fontSize: "1.1rem",
                    margin: "0 0.5rem",
                  }
              }
            >
              Custom
            </Button>
          </Link>
          <Link
            to={`/dashboard/feedback/survey/archived`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button

              variant={type === "archived" ? "contained" : "outlined"}
              startIcon={<ArchiveIcon />}
              style={
                type === "archived"
                  ? {
                    background: "#D06283",
                    textTransform: "capitalize",
                    margin: "0 0.5rem",
                    fontSize: "1.1rem",
                  }
                  : {
                    border: "1px solid #D06283",
                    color: "#484848",
                    textTransform: "capitalize",
                    fontSize: "1.1rem",
                    margin: "0 0.5rem",
                  }
              }
            >
              Archived
            </Button>
          </Link>
        </Box>
        <SettingsIcon
          onClick={() => {
            setConfigModalOpen(true);
          }}
          style={{
            color: "#727272",
            cursor: "pointer",
            zIndex: "400",
            margin: "0 1rem",
          }}
        />
      </Box>
      <Box style={{ maxWidth: "100vw", overflowX: "scroll" }}>
        {type === "insight" && (
          <Box className="survey">
            <Box
              sx={{
                height: "50px",
                padding: "2rem 0 0",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {`Insight Surveys`}
              </Typography>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              <div
                class="survey-search"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Metric
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currentMetric}
                      label="Metric"
                      onChange={(e) => {
                        setCurrentMetric(e.target.value);
                      }}
                    >
                      <MenuItem value={"morale"}>Morale</MenuItem>
                      <MenuItem value={"engagement"}>Engagement</MenuItem>
                      <MenuItem value={"retention"}>Retention</MenuItem>
                      <MenuItem value={"eNPS"}>eNPS</MenuItem>
                      <MenuItem value={"topic"}>Topics</MenuItem>
                      <MenuItem value={"culture"}>Culture</MenuItem>
                    </Select>
                  </FormControl>
                  <input
                    style={{ margin: "0 1rem", minWidth: "500px" }}
                    placeholder="Search Surveys"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setCustomModalOpen(true);
                    }}
                    variant="contained"
                    style={{
                      background: "#D06283",
                      textTransform: "capitalize",
                    }}
                  >
                    Create Custom Survey
                  </Button>
                </Box>
              </div>
              <table>
                <thead>
                  <tr>{headRow()}</tr>
                </thead>
                <tbody className="survey-trhover">{tableData()}</tbody>
              </table>
              <Pagination
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={filteredData.length}
              />
            </Box>
          </Box>
        )}
        {type === "baseline" && (
          <BaselineList setSeletedSurvey={setSeletedSurvey} />
        )}
        {type === "recurring" && <FavoriteList />}
        {type === "custom" && <CustomSurveyList />}
        {type === "archived" && <ArchivedList />}
        {/* <CompletedList /> */}
      </Box>
      <Modal
        open={launchModalOpen}
        onClose={() => {
          setLaunchModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LaunchModal
          survey={currentSurvey}
          handleClose={() => {
            setLaunchModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={customModalOpen}
        onClose={() => {
          setCustomModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomSurveyModal
          handleClose={() => {
            setCustomModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={archiveModalOpen}
        onClose={() => {
          setArchiveModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ArchiveModal
          survey={currentSurvey}
          handleClose={() => {
            setArchiveModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SettingsModal
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default SurveyList;